import React, { useState, useEffect } from 'react';

const IndicatorBar = ({ width, disableAnimation, color }) => {
  const [stateWidth, setWidth] = useState(0);

  useEffect(() => {
    function updateWidth() {
      if (disableAnimation) {
        setWidth(width);
      } else {
        setTimeout(() => {
          setWidth(width);
        }, 0);
      }
    }

    updateWidth(width);
  }, [width, disableAnimation]);

  return (
    <div
      className="indicator"
      style={{
        backgroundColor: color,
        width: stateWidth,
        transition: disableAnimation ? 'none' : 'width 1000ms ease-in-out'
      }}
    />
  );
};

IndicatorBar.defaultProps = {
  width: 0
};

export default IndicatorBar;
