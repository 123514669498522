import React from 'react';
import { CashIcon, CreditCardIcon, PrinterIcon, ShoppingCartIcon, TransactionIcon } from '../../assets/react-icons';
import { convertToUSD } from '../../utils';

const CashierCardLineItems = ({ userOverview = {} }) => {
  const {
    totalCashRevenue = 0,
    totalCreditRevenue = 0,
    totalReprints = 0,
    totalRevenue = 0,
    totalTransactions = 0
  } = userOverview;

  const cashierInfo = [
    {
      icon: <ShoppingCartIcon color="#000000" width={24} />,
      label: 'Revenue',
      metric: totalRevenue !== null ? convertToUSD(totalRevenue) : 'No Data'
    },
    {
      icon: <CashIcon color="#8BC34A" width={24} />,
      label: 'Cash Revenue',
      metric: totalCashRevenue !== null ? convertToUSD(totalCashRevenue) : 'No Data'
    },
    {
      icon: <CreditCardIcon color="#29B6F6" width={24} />,
      label: 'Credit Revenue',
      metric: totalCreditRevenue !== null ? convertToUSD(totalCreditRevenue) : 'No Data'
    },
    {
      icon: <TransactionIcon color="#34CBBD" width={24} />,
      label: 'Transactions',
      metric: totalTransactions
    },
    {
      icon: <PrinterIcon color="#EC407A" width={24} />,
      label: 'Reprints',
      metric: totalReprints
    }
  ];

  return (
    <ul className="cashier-ul">
      {cashierInfo.map((item) => {
        const { icon, label, metric } = item;
        const key = label.toLowerCase().replace(' ', '');

        return (
          <li key={key}>
            <span className="icon">{icon}</span>
            <div className="data">
              <span className="metric">{metric}</span>
              <span className="label">{label}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default CashierCardLineItems;
