import React, { useRef, useState } from 'react';
import { SearchInput } from '../../components';

const DailyEventSearch = ({ filterState = {}, setFilterState }) => {
  const [search, setSearch] = useState('');
  const timer = useRef(false);

  function handleSearchChange(e) {
    setSearch(e.target.value);
    clearTimeout(timer.current);
    const updateSearchFilter = () => setFilterState({ ...filterState, search });

    timer.current = setTimeout(updateSearchFilter, 1000);
  }

  return <SearchInput onChange={handleSearchChange} placeholder="Search by keyword" value={search} />;
};

export default DailyEventSearch;
