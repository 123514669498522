import axios from 'axios';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Cookies } from 'react-cookie';
import TagManager from 'react-gtm-module';
import { currencyFormat, kFormatter, numberWithCommas, percentFormat } from './chartconfig';
import { colors, getColor } from './colors';
import { FUSE_OPTIONS } from './fuseOptions';
import highchartsOptions from './highchartsOptions';

const cookies = new Cookies();

const ajax = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

export { getColor, colors, highchartsOptions, FUSE_OPTIONS, percentFormat, numberWithCommas, kFormatter, currencyFormat };

export function sendPageTitle(value) {
  const tagManagerArgs = {
    dataLayer: {
      page: value
    }
  };

  TagManager.dataLayer(tagManagerArgs);

  document.title = `Analytics | ${value}`;
}

export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

export function convertToUSD(number) {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });
}

export function createFilterList(data, keyName) {
  return _.uniqBy(data.map((item) => ({ name: item[keyName], id: item.id, isChecked: true })), 'name');
}

export function getDiffInMinutes(start, end) {
  const duration = moment.duration(moment.unix(end).diff(moment.unix(start)));
  const minutes = duration.asMinutes();

  return Math.round(minutes);
}

export function createFilterListDynamic(data, keyName, identifier = 'name') {

  return _.uniqBy(data.map((item) => ({
    name: resolvePropDepth(keyName, item),
    id: item.id, isChecked: false
  })), identifier);
}

export function getDiffInMinutesByTransactions(transactions) {
  if (!_.isEmpty(transactions)) { //Do nothing if list is empty to prevent errors
    const { first, last } = getFirstAndLastTransactionTime(transactions);

    return getDiffInMinutes(first, last);
  }
}

export function eventById(events, id) {
  if (!_.isEmpty(events)) {
    return _.filter(events, { 'id': id })[0];
  }
}

export function getFirstAndLastTransactionTime(transactions) {
  const sorted = orderByKey(transactions, 'transaction_time', 'asc');
  const first = sorted[0].transaction_time;
  const last = sorted[sorted.length - 1].transaction_time;

  return {
    first,
    last
  };
}

export function makeTimeReadable(date, timezone) {
  return {
    date: moment.unix(date).tz(timezone || 'America/Chicago')
      .format('MM/DD/YY'),
    time: moment.unix(date).tz(timezone || 'America/Chicago')
      .format('h:mm a'),
    tzcode: moment.unix(date).tz(timezone || 'America/Chicago').format('z')
  };
}

export function orderByKey(list, key, direction = 'asc') {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

export function resolvePropDepth(path, obj) {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
}

export const timeFormatter = (x) => {
  return x * 1000;
};

export function truncate(string, max) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}

export const uppercaseFirstLetter = (string) => string.split(' ').map(x => x[0].toUpperCase() + x.substring(1)).join(' ');

export const idFormatter = (str) => str.toLowerCase().split(' ').join('-');

export function formatFilterValue(value, char, position) {
  const v = value || 0;
  if (char) {
    return position === 'right' ? `${v.toLocaleString()}${char}` : `${char}${v.toLocaleString()}`;
  }
  else {
    return `${v.toLocaleString()}`;
  }
}

export function getCookie(name) {
  return cookies.get(name);
}

export function refreshSession() {
  const value = getCookie('86553b39');

  if (value && value !== 'undefined') {
    return ajax.post('authenticate?refreshCookie=true');

  } else {
    console.error(`Can not refresh session because current cookie expired`);
  }
}

export function deleteCookies() {
  cookies.remove('86553b39', {
    domain: '.parkhub.com'
  });
  cookies.remove('_gtmUID', {
    domain: '.parkhub.com'
  });
}

export function signOut(params = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + params}`);
}

export function checkForGetError(resp, successType, errorType, dispatch) {
  const data = resp.data;

  if (data.hasOwnProperty('errors') && data.errors[0].message.match(/SELECT/)) {
    dispatch({
      type: errorType,
      payload: 'Unexpected server error. Please contact administrator.'
    });

  } else if (data.hasOwnProperty('errors') && data.errors[0].message.match(/auth/)) {
    console.error('error calling auth endpoint');

    dispatch({
      type: errorType,
      payload: 'Authentication failed. Please log back in.'
    });

    setTimeout(() => signOut(), 4000);

  } else {
    dispatch({
      type: successType,
      payload: data.data.EventAnalytics
    });
  }
}

function checkForEnvMatch() {
  if (getCookie('86553b39')) {
    return getAuthUser().ne === process.env.REACT_APP_ENV_TAG;
  } else {
    return false;
  }
}

export const checkAuth = () => {
  const authCookie = getCookie('86553b39');

  if (authCookie && authCookie !== 'undefined' && checkForEnvMatch()) {
    return true;

  } else {
    deleteCookies();
    return false;
  }
};

export function getAuthUser() {
  return jwt.decode(getCookie('86553b39'), { complete: true }).payload.payload;
}

export function getSessionTime() {
  const iat = jwt.decode(getCookie('86553b39'), { complete: true }).payload.iat;
  return Math.abs((new Date(iat * 1000) - new Date()) / 1000 / 60);
}
