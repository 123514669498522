import React from 'react';
import {
  CarsParkedByType,
  CreditRevenue,
  ExemptionTypes,
  PermitTypes,
  PrepaidBreakdown,
  RedeemedPrepaids,
  Reprints,
  RevenueByType,
  RevenueRollups,
  TotalTransactions,
  VipsParked
} from '../../charts/';
import { OverviewChartCard } from '../../components';

const SummationCards = ({ data = {} }) => {
  const cards = [
    [
      { content: <RevenueRollups />, id: 'revenueRollups', title: 'Revenue Rollups' },
      { content: <TotalTransactions />, id: 'totalTransactions', title: 'Total Transactions' },
      { content: <CarsParkedByType />, id: 'carsParkedByType', title: 'Cars Parked by Type' },
      { content: <RedeemedPrepaids />, id: 'redeemedPrepaids', title: 'Redeemed Prepaids' },
      { content: <Reprints noChart />, id: 'receiptReprints', title: 'Reprints' }
    ],
    [
      { content: <RevenueByType noChart />, id: 'revenueByType', title: 'Revenue by Type' },
      { content: <PrepaidBreakdown />, id: 'prepaidBreakdown', title: 'Prepaid Breakdown' },
      { content: <VipsParked average />, id: 'vipsParked', title: 'VIPs Parked' },
      { content: <CreditRevenue />, id: 'creditRevenue', title: 'Credit Revenue' }
    ],
    [
      { content: <ExemptionTypes />, id: 'exemptionTypes', title: 'Exemption Types' },
      { content: <PermitTypes />, id: 'permitTypes', title: 'Permit Types' }
    ]
  ];

  return cards.map((column, columnIndex) => (
    <section className="chart-column col-xs-12 col-sm-6 col-md-4" key={`column-${columnIndex}`}>
      <div className="row">
        {column.map(card => {
          const { content, id, title } = card;

          return (
            <div className="col-xs-12" key={id}>
              <OverviewChartCard title={title} data={data[id]}>
                {content}
              </OverviewChartCard>
            </div>
          );
        })}
      </div>
    </section>
  ));
};

export default SummationCards;
