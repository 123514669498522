import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-md';
import { connect } from 'react-redux';
import { ClearFiltersMessage, Table, TransactionDialog } from '../../components';
import { sendPageTitle } from '../../utils/';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { getTransactionsByEvent, clearTransactionData } from '../../actions/transactions.action';
import { getTransactionCSV } from '../../actions/csvDownload.action';
import TransactionActionBar from './TransactionActionBar';
import { useRouteMatch } from 'react-router-dom';

const transactionsListLabelKeys = [
  { label: '', key: 'status', sortable: false },
  { label: 'Amount', key: 'total', sortable: false },
  { label: 'Type', key: 'pricing_name', sortable: false },
  { label: 'Method', key: 'transaction_type', sortable: false },
  { label: 'Cashier', key: 'user_name', sortable: false },
  { label: 'Location', key: 'lot_name', sortable: false },
  { label: 'Date/Time', key: 'transaction_time', sortable: false },
  { label: 'Confirmation', key: 'confirmation_code', sortable: false },
  { label: 'Source', key: 'source', sortable: false }
];

const Transactions = ({
  clearTransactionData,
  csvIsLoading,
  data = [],
  filters = {},
  getTransactionsByEvent,
  getTransactionCSV,
  isLoading,
  timezone
}) => {
  const match = useRouteMatch();
  const { params: { id } = {} } = match;
  const clearedDialog = {
    dialogData: {},
    dialogVisible: false,
    pageX: null,
    pageY: null
  };

  const clearedFilters = {
    cashierIdFilter: null,
    inventoryIdFilter: null,
    limit: 200,
    methodFilter: null,
    offset: null,
    pricePointFilter: null,
    search: null,
    sort: 'desc',
    statusFilter: null,
    timeFilter: null
  };

  const [dialogState, setDialogState] = useState(clearedDialog);
  const [filterState, setFilterState] = useState(clearedFilters);

  useMemo(() => {
    if (id) {
      getTransactionsByEvent(id, filterState);
    }
  }, [filterState, getTransactionsByEvent, id]);

  useEffect(() => {
    sendPageTitle('Transactions');

    return () => clearTransactionData();
  }, [clearTransactionData]);

  const hideDialog = () => setDialogState({ ...dialogState, dialogVisible: false });

  const showDialog = (e, dialogData) => {
    let { pageX, pageY } = e;
    if (e.changedTouches) {
      pageX = e.changedTouches[0].pageX;
      pageY = e.changedTouches[0].pageY;
    }

    setDialogState({ dialogData, dialogVisible: true, pageX, pageY });
  };

  const applyFilter = (value, key) => setFilterState({ ...filterState, [key]: value });

  const clearAllFilters = () => setFilterState(clearedFilters);

  const handleLoadMore = () => {
    const increment = 100;
    const newLimit = filterState.limit + increment;

    setFilterState({ ...filterState, limit: newLimit });
  };

  const handleDownload = () => getTransactionCSV(match.params.id, filterState);

  const canLoadMore = data.length >= filterState.limit;

  return (
    <>
      <div id="transactions">
        <TransactionActionBar applyFilter={applyFilter} filters={filters} handleDownload={handleDownload} />
        <section className="content-container">
          {data.length >= 1 && (
            <Table
              data={data}
              timezone={timezone}
              title="Transactions"
              labelKeys={transactionsListLabelKeys}
              onRowClick={showDialog}
            />
          )}
          {canLoadMore && (
            <div className="load-more-container center">
              <Button className="button blue-ghost" flat swapTheming onClick={handleLoadMore}>
                Load More
              </Button>
            </div>
          )}
        </section>
      </div>
      <TransactionDialog
        visible={dialogState.dialogVisible}
        pageX={dialogState.pageX}
        pageY={dialogState.pageY}
        onHide={hideDialog}
        data={dialogState.dialogData}
        title="Transaction Details"
      />
      <LoadingSpinner show={csvIsLoading || isLoading} delay={1000} />
      {!isLoading && data.length <= 0 && <ClearFiltersMessage clearAllFilters={clearAllFilters} />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state.transactions.data,
    timezone: state.transactions.timezone,
    filters: state.transactions.filters,
    isError: state.transactions.isError,
    isLoading: state.transactions.isLoading,
    csvIsLoading: state.csv.isLoading,
    csvIsError: state.csv.isError
  };
};

export default connect(mapStateToProps, {
  getTransactionsByEvent,
  getTransactionCSV,
  clearTransactionData
})(Transactions);
