import React, { Fragment } from 'react';
import { DonutChart } from '../../components';
import { EmptyChart } from '../../components';
import { percentFormat } from '../../utils';

export const Utilization = (props) => {
  const { data } = props;
  const dataAvailable = data.utilization.value > 0;

  let formattedData = [];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];
      for (let x in data) {
        if (data.hasOwnProperty(x)) {
          formattedArray.push({
            name: data[x].label,
            y: parseInt(data[x].value, 10),
            color: x === 'carsParked' ? 'rgb(255, 87, 34)' : 'rgb(236, 239, 241)',
            selected: true
          });
        };
      };

      return formattedArray.slice(1, 3);
    })();
  }

  return (
    <Fragment>
      {
        dataAvailable ?
          <DonutChart
            data={formattedData}
            name="Spots"
            subtitle="Utilization"
            total={percentFormat(data.utilization.value)}
          />
          :
          <EmptyChart />
      }
    </Fragment>
  );
};
