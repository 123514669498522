import React from 'react';
import { Button, DialogContainer, Toolbar } from 'react-md';
import './OverviewDialog.scss';
import { OverviewChartCard } from '../';
import {
  Activity,
  CarsParkedByType,
  CreditRevenue,
  ExemptionTypes,
  PermitTypes,
  PrepaidBreakdown,
  RevenueByType,
  RevenueRollups,
  TransactionalRate,
  TotalTransactions,
  Utilization
} from '../../charts/';

const OverviewDialog = ({ color = '#ff5722', visible, pageX, pageY, onHide, title = '', data = {} }) => {
  const { metrics = {} } = data;
  const {
    activity,
    carsParkedByType,
    creditRevenue,
    exemptionTypes,
    permitTypes,
    prepaidBreakdown,
    revenueByType,
    revenueRollups,
    totalTransactions,
    transactionRate,
    utilization
  } = metrics;

  const inventoryCharts = [
    {
      chartTitle: 'Activity',
      chartData: activity,
      chartComponent: <Activity />
    },
    {
      chartTitle: 'Utilization',
      chartData: utilization,
      chartComponent: <Utilization />
    }
  ];

  const cashierChart = [
    {
      chartTitle: 'Transaction Rate',
      chartData: transactionRate,
      chartComponent: <TransactionalRate />
    }
  ];

  const charts = [
    [
      {
        chartTitle: 'Revenue Rollups',
        chartData: revenueRollups,
        chartComponent: <RevenueRollups />
      },
      {
        chartTitle: 'Total Transactions',
        chartData: totalTransactions,
        chartComponent: <TotalTransactions />
      },
      {
        chartTitle: 'Cars Parked by Type',
        chartData: carsParkedByType,
        chartComponent: <CarsParkedByType />
      },
      {
        chartTitle: 'Credit Revenue',
        chartData: creditRevenue,
        chartComponent: <CreditRevenue />
      }
    ],
    [
      {
        chartTitle: 'Revenue by Type',
        chartData: revenueByType,
        chartComponent: <RevenueByType />
      },
      {
        chartTitle: 'Prepaid Breakdown',
        chartData: prepaidBreakdown,
        chartComponent: <PrepaidBreakdown />
      },
      {
        chartTitle: 'Permit Types',
        chartData: permitTypes,
        chartComponent: <PermitTypes />
      }
    ],
    [
      ...(title.includes('Inventory') ? inventoryCharts : cashierChart),
      {
        chartTitle: 'Exemption Types',
        chartData: exemptionTypes,
        chartComponent: <ExemptionTypes />
      }
    ]
  ];

  return (
    <DialogContainer
      id="overview-dialog"
      className="focus-dialog"
      visible={visible}
      pageX={pageX}
      pageY={pageY}
      fullPage
      aria-labelledby="overview-dialog-title"
      disableScrollLocking
      lastChild
      portal
    >
      <Toolbar
        fixed
        colored
        title={title}
        nav={
          <Button id="dialog-back-button" icon onClick={onHide}>
            arrow_back
          </Button>
        }
        actions={
          <Button id="dialog-options-button" icon onClick={onHide}>
            more_vert
          </Button>
        }
        style={{ backgroundColor: color }}
      />
      <div className="wrapper">
        <div className="col-xs-12">
          <div className="dialog-action-container">
            <Button id="dialog-favorite" className="action-button icon-button" icon>
              star
            </Button>
            <Button id="dialog-share" className="action-button icon-button" icon>
              share
            </Button>
          </div>
        </div>
        <div className="row">
          {charts.map((column, index) => (
            <div className="col-xs-12 col-md-4" key={`column-${index + 1}`}>
              {column.map(chart => {
                const { chartComponent, chartData, chartTitle } = chart;

                return (
                  <OverviewChartCard data={chartData} key={chartTitle} title={chartTitle}>
                    {chartComponent}
                  </OverviewChartCard>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </DialogContainer>
  );
};

export default OverviewDialog;
