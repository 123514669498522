import * as types from '../actions/ActionTypes';

const INITIAL_STATE = {
  isLoading: true,
  isError: null,
  data: []
};

export function landmarksReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_LANDMARKS_PENDING: {
      return {
        ...state,
        isError: null,
        isLoading: true
      };
    }
    case types.GET_LANDMARKS_SUCCESS:
      return {
        ...state,
        data: action.payload.landmark.records,
        isError: null,
        isLoading: false
      };
    case types.GET_LANDMARKS_REJECTED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };
    default: return state;
  }
}
