import React, { useContext } from 'react';
import { DailyEventContext } from './DailyEventContext';
import { Button } from 'react-md';
import { EventCard } from '../../components';
import { isNotEmpty } from '../../utils';
import * as routes from '../../constants/routes';
import { useHistory } from 'react-router-dom';

const DailyEventList = () => {
  const history = useHistory() || {};
  const { events, filterState, setFilterState, view } = useContext(DailyEventContext);

  const { limit } = filterState;
  const { DAILYEVENT, METRICS, OVERVIEW } = routes;

  const canLoadMore = events.length >= limit;

  const handleLoadMore = () => {
    const increment = 100;
    const newLimit = filterState.limit + increment;

    setFilterState({ ...filterState, limit: newLimit });
  };

  function goToEvent(event) {
    const { id } = event;

    history.push({
      pathname: `${DAILYEVENT}${METRICS}${OVERVIEW}/${id}`,
      state: event
    });
  }

  return (
    <>
      {isNotEmpty(events) && events.length > 0 && (
        <ul className={`event-list ${view}`}>
          {events.map((event, index) => (
            <li id={`event-card-${index}`} key={event.id} className="event-item">
              <EventCard
                event={event.name}
                list={view === 'list'}
                landmark={event.landmark.name}
                timezone={event.landmark.timezone}
                onClick={() => goToEvent(event)}
                tag={event.product}
                timestamp={event.from}
              />
            </li>
          ))}
        </ul>
      )}
      {canLoadMore && (
        <Button className="button blue-ghost load-more-button" flat onClick={handleLoadMore} swapTheming>
          Load More
        </Button>
      )}
    </>
  );
};

export default DailyEventList;
