import React from 'react';
import { Table } from '../../components';

const CashierTable = ({ cashiersData = [], showDialog }) => {
  
  const cashierListLabelKeys = [
    { label: 'Name', key: 'display_name', sortable: true },
    { label: 'Total Transactions', key: 'metrics.userOverview.totalTransactions', sortable: true },
    { label: 'Total Revenue', key: 'metrics.userOverview.totalRevenue', sortable: true },
    { label: 'Total Cash', key: 'metrics.userOverview.totalCashRevenue', sortable: true },
    { label: 'Reprints', key: 'metrics.userOverview.totalReprints', sortable: true },
    { label: 'Location', key: 'lots.name', sortable: false }
  ];

  const cashiers = cashiersData.map(item => (
    { ...item, display_name: item.display_name.toLowerCase() }
  ));

  return (
    <Table 
      title="Cashiers"
      data={cashiers}
      labelKeys={cashierListLabelKeys}
      onRowClick={showDialog}
      sortable 
    />
  );
};

export default CashierTable;
