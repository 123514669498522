import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-md';
import { useHistory } from 'react-router-dom';
import * as routes from '../../../constants/routes';
import { getEventsByUser, clearEventsData } from '../../../actions/events.action';
import { connect } from 'react-redux';
import { isNotEmpty } from '../../../utils';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { EventCard, ViewToggle } from '../../../components';
import { setItem, getItem } from '../../../utils/sessionStorage';
import MultipleEventsTable from './MultipleEventsTable';
import MultipleEventsSearch from './MultipleEventsSearch';
import MultipleEventsFilter from './MultipleEventsFilter';

const MultipleEvents = ({ clearEventsData, events = [], filters = {}, getEvents, isLoading, match = {} }) => {
  const history = useHistory();
  const { path = '' } = match;
  const { LANDMARKS, METRICS, RANKINGS, ROLLUPS } = routes;
  const clearedFilterState = {
    limit: 200,
    offset: 0,
    productIdFilter: null,
    search: '',
    timeFilter: null
  };

  const [filterState, setFilterState] = useState(clearedFilterState);
  const { limit } = filterState;
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [view, setView] = useState('card');

  useEffect(() => setItem('selectedEvents', []), []);

  const loadData = useCallback(() => {
    getEvents({
      ...filterState,
      landmarkIdFilter: getItem('selectedLandmarks')
    });
  }, [filterState, getEvents]);

  useEffect(() => clearEventsData(), [clearEventsData]);
  useEffect(() => loadData(), [filterState, loadData]);

  const handleNavigateBack = () => history.push(path + LANDMARKS);

  const handleNavigateForward = () => {
    history.push({
      pathname: ROLLUPS + METRICS + RANKINGS
    });
  };

  function handleLoadMore() {
    const increment = 100;
    const newLimit = limit + increment;

    return setFilterState({ ...filterState, limit: newLimit });
  }

  const checkForSelected = (id) => selectedEvents.includes(id);

  function updateSelectedEvents(events) {
    setSelectedEvents(events);
    setItem('selectedEvents', events);
  }

  function selectSingleEvent(e, { id }) {
    const selectedEvents = getItem('selectedEvents');
    const updatedEvents = checkForSelected(id) ? selectedEvents.filter((item) => item !== id) : [...selectedEvents, id];

    return updateSelectedEvents(updatedEvents);
  }

  const canLoadMore = events.length >= limit;
  const isSingleLandmark = getItem('landmarksLength') !== 1;

  return (
    <>
      <div id="multiple-events">
        <section className="row collapse middle-xs page-title-separator">
          {isSingleLandmark && (
            <div className="col-xs-2 col-sm-1">
              <Button onClick={() => handleNavigateBack()} icon>
                arrow_back
              </Button>
            </div>
          )}
          <div className="col-xs col-sm">
            <h2 className="page-title">Multiple Events Selection</h2>
          </div>
          <div className="col-xs-12 col-sm-2">
            <div className="row row-clear middle-xs end-xs">
              <ViewToggle toggle={setView} view={view} />
            </div>
          </div>
        </section>
        <section className="top-action-bar">
          <div className="row collapse middle-xs">
            <MultipleEventsSearch filterState={filterState} setFilterState={setFilterState} />
            <MultipleEventsFilter
              clearedFilterState={clearedFilterState}
              filters={filters}
              filterState={filterState}
              setFilterState={setFilterState}
            />
          </div>
        </section>
        {!isLoading && (
          <section className="row row-clear">
            <div className="col-xs-12">
              {isNotEmpty(events) && view === 'card' && (
                <div className="row">
                  {events.map((event, index) => (
                    <div id={`event-card-${index}`} key={index} className="col-xs-12 col-sm-6 col-md-4">
                      <EventCard
                        list={false}
                        tag={event.product}
                        timestamp={event.from}
                        event={event.name}
                        landmark={event.landmark.name}
                        eventId={event.id}
                        onClick={selectSingleEvent}
                        selectable
                        selectedIds={selectedEvents}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
              )}
              {isNotEmpty(events) && view === 'list' && (
                <MultipleEventsTable
                  events={events}
                  selectedEvents={selectedEvents}
                  selectSingleEvent={selectSingleEvent}
                />
              )}
              <section className="content-container">
                <div className="load-more-container center">
                  {canLoadMore && (
                    <Button className="button blue-ghost" flat swapTheming onClick={handleLoadMore}>
                      Load More
                    </Button>
                  )}
                </div>
              </section>
            </div>
          </section>
        )}
        {selectedEvents.length > 1 && (
          <Button className="main-action-button" onClick={() => handleNavigateForward()} floating primary>
            arrow_forward
          </Button>
        )}
      </div>
      <LoadingSpinner show={isLoading} delay={1000} />
    </>
  );
};

const mapStateToProps = (state) => ({
  events: state.events.data,
  filters: state.events.filters,
  isError: state.events.isError,
  isLoading: state.events.isLoading
});

export default connect(mapStateToProps, {
  getEvents: getEventsByUser,
  clearEventsData
})(MultipleEvents);
