import * as types from './ActionTypes';
import { create } from 'axios';
import compress from 'graphql-query-compress';
import { checkForGetError } from '../utils/';
import { loader } from 'graphql.macro';

const landmarksQuery = loader('./queries/landmarksQuery.gql');

const ajax = create({
  timeout: 100000,
  contentType: 'application/json',
  withCredentials: true
});

export const getLandmarks = params => dispatch => {
  dispatch({
    type: types.GET_LANDMARKS_PENDING
  });

  ajax
    .get(process.env.REACT_APP_ANALYTICS_SERVER, {
      method: 'GET',
      params: {
        query: compress(landmarksQuery.loc.source.body)
      }
    })
    .then(resp => {
      checkForGetError(resp, types.GET_LANDMARKS_SUCCESS, types.GET_LANDMARKS_REJECTED, dispatch);
    })
    .catch(error => {
      dispatch({
        type: types.GET_LANDMARKS_REJECTED,
        payload: error
      });
    });
};
