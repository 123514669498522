import React from 'react';
import { Button, DialogContainer, Toolbar } from 'react-md';
import './BasicDialog.scss';

const BasicDialog = ({ visible, pageX, pageY, onHide, color = '#ff5722', title, children }) => (
  <DialogContainer
    id="basic-dialog"
    className="focus-dialog"
    visible={visible}
    pageX={pageX}
    pageY={pageY}
    fullPage
    aria-labelledby={title}
    disableScrollLocking
    lastChild
    portal
  >
    <Toolbar
      fixed
      colored
      title={title || ''}
      nav={
        <Button id="dialog-back-button" icon onClick={onHide}>
          arrow_back
        </Button>
      }
      style={{ backgroundColor: color }}
    />
    <section>
      <div className="wrapper">
        <div className="col-xs-12">
          <div className="dialog-action-container" />
        </div>
        <div className="row">
          <div className="col-xs-12">{children}</div>
        </div>
      </div>
    </section>
  </DialogContainer>
);

export default BasicDialog;
