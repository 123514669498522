import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { getRankingsByEvents, clearRankingsData } from '../../actions/rankings.action';
import { NoDataMessage, OverviewChartCard, BasicDialog } from '../../components';
import { isNotEmpty, sendPageTitle } from '../../utils';
import { numberWithCommas } from '../../utils/chartconfig';
import { getItem } from '../../utils/sessionStorage';
import { RankingsChart } from '../../charts/';
import RankingsCards from './RankingsCards';

const Rankings = ({ clearRankingsData, data = {}, getRankingsByEvents, isLoading = true }) => {
  const clearedDialog = {
    dialogColor: '#FF5722',
    dialogData: {},
    dialogFormatter: numberWithCommas,
    dialogTitle: '',
    dialogVisible: false,
    pageX: null,
    pageY: null
  };

  const [dialogState, setDialogState] = useState(clearedDialog);

  useEffect(() => {
    getRankingsByEvents(getItem('selectedEvents'));
    sendPageTitle('Rankings');

    return () => clearRankingsData();
  }, [clearRankingsData, getRankingsByEvents]);

  const showDialog = (e, dialogData, dialogTitle, dialogColor, dialogFormatter) => {
    let { pageX, pageY } = e;

    if (e.changedTouches) {
      pageX = e.changedTouches[0].pageX;
      pageY = e.changedTouches[0].pageY;
    }

    setDialogState({
      dialogColor,
      dialogData,
      dialogFormatter,
      dialogVisible: true,
      dialogTitle,
      pageX,
      pageY
    });
  };

  const hideDialog = () => setDialogState({ ...dialogState, dialogVisible: false });

  const disableDialogAnimation = true;

  return (
    <Fragment>
      <div id="rankings" className="row">
        {isNotEmpty(data) ? <RankingsCards data={data} showDialog={showDialog} /> : !isLoading && <NoDataMessage />}
      </div>
      <BasicDialog
        visible={dialogState.dialogVisible}
        pageX={dialogState.pageX}
        pageY={dialogState.pageY}
        onHide={hideDialog}
        data={dialogState.dialogData}
        title={`Rankings / ${dialogState.dialogTitle}`}
      >
        <OverviewChartCard title={dialogState.dialogTitle} data={dialogState.dialogData}>
          <RankingsChart
            formatter={dialogState.dialogFormatter}
            color={dialogState.dialogColor}
            valueKeyPath="value.value"
            disableAnimation={disableDialogAnimation}
          />
        </OverviewChartCard>
      </BasicDialog>
      <LoadingSpinner show={isLoading} delay={1000} />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    data: state.rankings.data,
    isError: state.rankings.isError,
    isLoading: state.rankings.isLoading
  };
};

export default connect(mapStateToProps, {
  getRankingsByEvents,
  clearRankingsData
})(Rankings);
