import { MapLayer, withLeaflet } from 'react-leaflet';
import 'leaflet.markercluster';
import L from 'leaflet';

// https://github.com/YUzhva/react-leaflet-markercluster/issues/71#issuecomment-466393028
class MarkerClusterGroup extends MapLayer {
  createLeafletElement(props) {
    // @ts-ignore
    const el = new L.markerClusterGroup(props);
    this.contextValue = {
      ...props.leaflet,
      layerContainer: el
    };
    return el;
  }
}

export default withLeaflet(MarkerClusterGroup);
