import React, { Fragment } from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, Chart, withHighcharts, LineSeries, YAxis, XAxis, Tooltip } from 'react-jsx-highcharts';
import { CHART_COLORS, colorIndexOf } from '../../utils/chartconfig';
import ChartLegend from '../ChartLegend/ChartLegend';
import * as Moment from 'moment';

window.moment = Moment; // Required for Timezone with HighCharts

const LineChart = ({ data: chartData = [], legendData = [], timezone = 'America/Chicago', total }) => {
  const markerOptions = {
    fillColor: '#FFFFFF',
    lineWidth: 2,
    lineColor: null,
    symbol: 'circle'
  };

  const chartLines = () =>
    chartData.map(series => {
      const { data: seriesData = [], name = '' } = series;

      return (
        <LineSeries
          key={`${name}-series`}
          name={name}
          color={CHART_COLORS[colorIndexOf(name)]}
          marker={markerOptions}
          data={seriesData}
          lineWidth={2}
        />
      );
    });

  return (
    <Fragment>
      <HighchartsChart
        chart={{
          height: '220px'
        }}
        className="line-chart"
        time={{
          timezone
        }}
      >
        <Chart />
        <Tooltip
          backgroundColor="white"
          // COME BACK Date Time currently showing Day, Month, Date, H,M
          xDateFormat="%a., %b %e, %l:%M%P"
        />
        <XAxis
          type="datetime"
          gridLineWidth="1px"
          labels={{
            format: '{value:%l:%M}',
            style: {
              color: '#666666',
              'font-size': '14px'
            }
          }}
        />
        <YAxis
          gridLineWidth="0px"
          labels={{
            enabled: false
          }}
        >
          {chartLines()}
        </YAxis>
      </HighchartsChart>
      <ChartLegend data={legendData ? legendData : chartData} total={total && total} />
    </Fragment>
  );
};

export default withHighcharts(LineChart, Highcharts);
