import React from 'react';
import { ListCard } from '../../components';
import CashierCardLineItems from './CashierCardLineItems';

const CashierCards = ({ cashiers = [], showDialog }) => (
  <div className="row row-clear">
    {cashiers.map((cashier, index) => {
      const { id = '', metrics: { userOverview = {} } = {} } = cashier;

      return (
        <div
          id={`cashier-card-${index}`}
          key={id}
          onClick={e => showDialog(e, cashier)}
          className="col-xs-12 col-sm-6 col-md-3"
        >
          <ListCard icon item={cashier}>
            <CashierCardLineItems userOverview={userOverview} />
          </ListCard>
        </div>
      );
    })}
  </div>
);

export default CashierCards;
