import './TransactionDialog.scss';
import React from 'react';
import { Button, DialogContainer, Toolbar } from 'react-md';
import {
  AssessmentIcon,
  CircleCheckIcon,
  CreditCardIcon,
  ErrorOutlineIcon,
  Event,
  HelpOutlineIcon,
  HighlightOffIcon,
  LabelIcon,
  PersonIcon,
  PlacesIcon
} from '../../assets/react-icons';
import { makeTimeReadable } from '../../utils';

const TransactionDialog = ({ color = '#ff5722', data, onHide, pageX, pageY, visible }) => {
  const {
    failure_message,
    lot_name,
    offline_failure_message,
    pricing_name,
    status,
    transaction_time,
    transaction_type,
    user_name
  } = data;

  let statusColor = '';
  switch (data.status) {
    case 'failed':
      statusColor = '#ef5350';
      break;
    case 'pending':
      statusColor = '#fdd835';
      break;
    default:
      statusColor = '#00c853';
  }

  const { date, time } = makeTimeReadable(data.transaction_time);

  return (
    <DialogContainer
      aria-labelledby="transaction-dialog-title"
      disableScrollLocking
      height="576px"
      id="transaction-dialog"
      lastChild
      onHide={onHide}
      pageX={pageX}
      pageY={pageY}
      portal
      visible={visible}
      width="532px"
    >
      <Toolbar
        actions={
          <Button id="transaction-dialog-close" icon onClick={onHide}>
            close
          </Button>
        }
        colored
        fixed
        style={{ backgroundColor: color }}
        title="Transaction Details"
        titleId="transaction-dialog-title"
      />
      <ul>
        {status && (
          <li>
            <span className="icon">
              {status === 'failed' && <HighlightOffIcon color={statusColor} width={24} />}
              {status === 'pending' && <ErrorOutlineIcon color={statusColor} width={24} />}
              {!['failed', 'pending'].includes(status) && <CircleCheckIcon color={statusColor} width={24} />}
            </span>
            <div className="data">
              <span className="metric" style={{ color: statusColor }}>
                {data.status}
              </span>
              <span className="label">Status</span>
            </div>
          </li>
        )}
        {status === 'failed' && (failure_message || offline_failure_message) && (
          <li>
            <span className="icon">
              <HelpOutlineIcon color="rgba(0,0,0, .6)" width={24} />
            </span>
            <div className="data">
              <span className="metric">{failure_message || offline_failure_message}</span>
              <span className="label">Reason for Failure</span>
            </div>
          </li>
        )}
        <li>
          <span className="icon">
            <LabelIcon color="rgba(0,0,0, .6)" width={24} />
          </span>
          <div className="data">
            <span className="metric">{pricing_name || 'N/A'}</span>
            <span className="label">Type</span>
          </div>
        </li>
        {transaction_type && (
          <li>
            <span className="icon">
              <AssessmentIcon color="rgba(0,0,0, .6)" width={24} />
            </span>
            <div className="data">
              <span className="metric">{transaction_type}</span>
              <span className="label">Method</span>
            </div>
          </li>
        )}
        {pricing_name && (
          <li>
            <span className="icon">
              <CreditCardIcon color="rgba(0,0,0, .6)" width={24} />
            </span>
            <div className="data">
              <span className="metric">{pricing_name}</span>
              <span className="label">Pricing Type</span>
            </div>
          </li>
        )}
        {user_name && (
          <li>
            <span className="icon">
              <PersonIcon color="rgba(0,0,0, .6)" width={24} />
            </span>
            <div className="data">
              <span className="metric">{user_name}</span>
              <span className="label">Cashier</span>
            </div>
          </li>
        )}
        {lot_name && (
          <li>
            <span className="icon">
              <PlacesIcon color="rgba(0,0,0, .6)" width={24} />
            </span>
            <div className="data">
              <span className="metric">{lot_name}</span>
              <span className="label">Location</span>
            </div>
          </li>
        )}
        {transaction_time && (
          <li>
            <span className="icon">
              <Event color="rgba(0,0,0, .6)" width={24} />
            </span>
            <div className="data">
              <span className="metric">{`${date} ${time}`}</span>
              <span className="label">Time</span>
            </div>
          </li>
        )}
      </ul>
    </DialogContainer>
  );
};

export default TransactionDialog;
