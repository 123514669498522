import React from 'react';
import { createFilterList, isNotEmpty } from '../../utils';
import { FilterBar, FilterChip } from '../../components';

const OverviewActionBar = ({ applyFilter, filters = {}, removeFilter }) => {

  return (
    <section className="top-action-bar">
      <div className="row collapse-sides">
        <div className="col-xs-12">
          <div className="action-container">
            {process.env.REACT_APP_FILTERS_ENABLED && isNotEmpty(filters) && (
              <FilterBar>
                {isNotEmpty(filters.inventory) &&
                  <FilterChip
                    data={createFilterList(filters.inventory, 'name')}
                    filterKey="inventoryIdFilter"
                    id="overview-locations-filter"
                    label="Lots"
                    onApply={applyFilter}
                  />
                }
                {isNotEmpty(filters.products) && filters.products.length > 1 &&
                  <FilterChip
                    data={createFilterList(filters.products, 'name')}
                    filterKey="productIdFilter"
                    label="Products"
                    id="overview-products-filter"
                    onApply={applyFilter}
                    onRemove={removeFilter}
                  />
                }
              </FilterBar>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverviewActionBar;
