import React, { Fragment } from 'react';
import { RollupMetrics } from '../../components';
import { EmptyChart } from '../../components';
import { isNotEmpty } from '../../utils';

export const VipsParked = (props) => {
  const { data, average } = props;

  const dataAvailable = isNotEmpty(data) && (data.total.value > 0);

  let current = 0;
  let total = 0;

  if (dataAvailable) {
    current = data.current.value;
    total = data.total.value;
  }

  return (
    <Fragment>
      {
        dataAvailable ?
          <RollupMetrics
            data={average ?
              [{ label: `Average VIP's Parked`, value: parseFloat((current/total) * 100).toFixed(0) + '%' }] :
              [{ label: `Average VIP's Parked`, value: `${current} / ${total}` }]
            }
          /> : <EmptyChart />
      }
    </Fragment>
  );
};
