import React, { useEffect } from 'react';
import * as routes from '../../constants/routes';
import { sendPageTitle } from '../../utils';
import { Switch, Route, withRouter } from 'react-router-dom';
import Landmarks from './Landmarks/Landmarks';
import MultipleEvents from './MultipleEvents/MultipleEvents';
import { setItem, getItem } from '../../utils/sessionStorage';

const Rollups = props => {
  const { history = {}, location = {}, match = {} } = props;
  const { pathname = '' } = location;
  const { path = '' } = match;
  const { LANDMARKS, MULTIPLEEVENTS, ROLLUPS } = routes;

  useEffect(() => {
    if (pathname === ROLLUPS) {
      history.push(path + LANDMARKS);
    }
  }, [history, LANDMARKS, path, pathname, ROLLUPS]);

  useEffect(() => {
    if (!getItem('selectedEvents')) {
      setItem('selectedEvents', []);
    }
    if (!getItem('selectedLandmarks')) {
      setItem('selectedLandmarks', []);
    }
  }, []);

  useEffect(() => sendPageTitle('Roll-ups'), []);

  return (
    <Switch location={location}>
      <Route
        exact
        path={`${path + LANDMARKS}`}
        render={() => <Landmarks {...props} />}
      />
      <Route
        exact
        path={`${path + MULTIPLEEVENTS}`}
        render={() => <MultipleEvents {...props} />}
      />
    </Switch>
  );
};

export default withRouter(Rollups);
