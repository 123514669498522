import React from 'react';
import { Button } from 'react-md';

const ViewToggle = ({ toggle, view = 'list' }) => {
  const toggleView = () => (view === 'card' ? toggle('list') : toggle('card'));
  const viewToggleText = view === 'list' ? 'card' : 'list';

  return (
    <Button
      className="action-button large view-toggle-button"
      id={`inventory-${viewToggleText}-view-toggle`}
      flat
      iconChildren={`view_${view === 'list' ? 'module' : 'list'}`}
      onClick={toggleView}
      type="button"
    >
      {`${viewToggleText} view`}
    </Button>
  );
};

export default ViewToggle;
