export const GET_EVENTS_PENDING = 'GET_EVENTS_PENDING';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_REJECTED = 'GET_EVENTS_REJECTED';
export const GET_LANDMARKS_PENDING = 'GET_LANDMARKS_PENDING';
export const GET_LANDMARKS_SUCCESS = 'GET_LANDMARKS_SUCCESS';
export const GET_LANDMARKS_REJECTED = 'GET_LANDMARKS_REJECTED';
export const GET_LAYOUT_PENDING = 'GET_LAYOUT_PENDING';
export const GET_LAYOUT_SUCCESS = 'GET_LAYOUT_SUCCESS';
export const GET_LAYOUT_REJECTED = 'GET_LAYOUT_REJECTED';
export const GET_OVERVIEW_PENDING = 'GET_OVERVIEW_PENDING';
export const GET_OVERVIEW_SUCCESS = 'GET_OVERVIEW_SUCCESS';
export const GET_OVERVIEW_REJECTED = 'GET_OVERVIEW_REJECTED';
export const GET_TRANSACTIONS_PENDING = 'GET_TRANSACTIONS_PENDING';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_REJECTED = 'GET_TRANSACTIONS_REJECTED';
export const GET_TRANSACTIONS_CSV_PENDING = 'GET_TRANSACTIONS_CSV_PENDING';
export const GET_TRANSACTIONS_CSV_SUCCESS = 'GET_TRANSACTIONS_CSV_SUCCESS';
export const GET_TRANSACTIONS_CSV_REJECTED = 'GET_TRANSACTIONS_CSV_REJECTED';
export const GET_INVENTORY_PENDING = 'GET_INVENTORY_PENDING';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_REJECTED = 'GET_INVENTORY_REJECTED';
export const GET_CASHIERS_PENDING = 'GET_CASHIERS_PENDING';
export const GET_CASHIERS_SUCCESS = 'GET_CASHIERS_SUCCESS';
export const GET_CASHIERS_REJECTED = 'GET_CASHIERS_REJECTED';
export const AUTH_USER_SET = 'AUTH_USER_SET';
export const CLEAR_EVENTS_DATA = 'CLEAR_EVENTS_DATA';
export const CLEAR_OVERVIEW_DATA = 'CLEAR_OVERVIEW_DATA';
export const CLEAR_INVENTORY_DATA = 'CLEAR_INVENTORY_DATA';
export const CLEAR_CASHIER_DATA = 'CLEAR_CASHIER_DATA';
export const CLEAR_TRANSACTION_DATA = 'CLEAR_TRANSACTION_DATA';
export const CLEAR_RANKINGS_DATA = 'CLEAR_RANKINGS_DATA';
export const CLEAR_AVERAGE_DATA = 'CLEAR_AVERAGE_DATA';
export const CLEAR_SUMMATION_DATA = 'CLEAR_SUMMATION_DATA';
export const GET_AVERAGE_PENDING = 'GET_AVERAGE_PENDING';
export const GET_AVERAGE_SUCCESS = 'GET_AVERAGE_SUCCESS';
export const GET_AVERAGE_REJECTED = 'GET_AVERAGE_REJECTED';
export const GET_RANKINGS_PENDING = 'GET_RANKINGS_PENDING';
export const GET_RANKINGS_SUCCESS = 'GET_RANKINGS_SUCCESS';
export const GET_RANKINGS_REJECTED = 'GET_RANKINGS_REJECTED';
export const GET_SUMMATION_PENDING = 'GET_SUMMATION_PENDING';
export const GET_SUMMATION_SUCCESS = 'GET_SUMMATION_SUCCESS';
export const GET_SUMMATION_REJECTED = 'GET_SUMMATION_REJECTED';
