import * as types from '../actions/ActionTypes';

const INITIAL_STATE = {
  isLoading: false,
  isError: null
};

export function csvDownloadReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_TRANSACTIONS_CSV_PENDING: {
      return {
        ...state,
        isLoading: true,
        isError: null
      };
    }
    case types.GET_TRANSACTIONS_CSV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: null
      };
    case types.GET_TRANSACTIONS_CSV_REJECTED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };
    default: return state;
  }
}
