import React from 'react';
import { FontIcon } from 'react-md';
import { Table } from '../../../components';

const LandmarksTable = ({
  landmarks = [],
  selectedLandmarks = [],
  selectSingleLandmark
}) => {
  const landmarksLabelKeys = [
    { label: '', key: 'id', sortable: false },
    { label: 'Venue Name', key: 'name', sortable: true },
    { label: 'City', key: 'city', sortable: true },
    { label: 'State', key: 'state', sortable: true }
  ];

  const LandmarkIcon = (
    <FontIcon style={{ color: 'rgba(0,0,0,0.6)', fontSize: '24px' }}>
      room
    </FontIcon>
  );

  return (
    <div className="row collapse end-xs">
      <div className="col-xs-12">
        <Table
          title="Landmarks"
          iconBefore={LandmarkIcon}
          data={landmarks}
          labelKeys={landmarksLabelKeys}
          selectable
          defaultSortKey={landmarksLabelKeys[1].key}
          defaultSortDirection="asc"
          selectedIds={selectedLandmarks}
          onRowClick={selectSingleLandmark}
        />
      </div>
    </div>
  );
};

export default LandmarksTable;
