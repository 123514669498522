import React, { useContext } from 'react';
import { FilterBar, FilterChip, FilterChipDateRange, ViewToggle } from '../../components';
import DailyEventSearch from './DailyEventSearch';
import { DailyEventContext } from './DailyEventContext';
import { createFilterList, isNotEmpty } from '../../utils';

const DailyEventActionBar = () => {
  const { clearedFilters, filters, filterState, setFilterState, setView, view } = useContext(DailyEventContext);
  function removeFilter(key) {
    setFilterState({ ...filterState, [key]: clearedFilters[key] });
  }

  function applyFilter(value, key) {
    setFilterState({ ...filterState, [key]: value });
  }

  return (
    <div className="action-container">
      <DailyEventSearch filterState={filterState} setFilterState={setFilterState} />
      {process.env.REACT_APP_FILTERS_ENABLED && isNotEmpty(filters) && (
        <FilterBar>
          {isNotEmpty(filters.landmarks) && (
            <FilterChip
              data={createFilterList(filters.landmarks, 'name')}
              direction="right"
              id="daily-event-type-filter"
              label="Locations"
              onApply={applyFilter}
              onRemove={() => setFilterState({ ...filterState, landmarkIdFilter: [] })}
              filterKey="landmarkIdFilter"
            />
          )}
          {isNotEmpty(filters.products) && (
            <FilterChip
              data={createFilterList(filters.products, 'name')}
              direction="right"
              id="daily-event-type-filter"
              label="Event Type"
              onApply={applyFilter}
              filterKey="productIdFilter"
            />
          )}
          {isNotEmpty(filters.time) && (
            <FilterChipDateRange
              direction="right"
              endDate={filters.time[1]}
              id="daily-event-date-filter"
              label="Date"
              onApply={applyFilter}
              onRemove={removeFilter}
              startDate={filters.time[0]}
              filterKey="timeFilter"
            />
          )}
        </FilterBar>
      )}
      <ViewToggle toggle={setView} view={view} />
    </div>
  );
};

export default DailyEventActionBar;
