import './FilterChip.scss';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontIcon, Button } from 'react-md';
import InputRange from 'react-input-range';
import { idFormatter } from '../../utils';

export class FilterChipSlider extends Component {

  static get propTypes() {
    return {
      direction: PropTypes.string,
      filterKey: PropTypes.string,
      formatLabel: PropTypes.func,
      label: PropTypes.string.isRequired,
      maxValue: PropTypes.number,
      minValue: PropTypes.number,
      onApply: PropTypes.func,
      onRemove: PropTypes.func
    };
  }

  static defaultProps = {
    direction: 'right'
  }

  constructor(props) {
    super(props);

    this._element = React.createRef();
  }

  state = {
    expanded: false,
    removable: false,
    value: {
      min: this.props.minValue,
      max: this.props.maxValue
    },
    maxValue: this.props.maxValue || 0,
    minValue: this.props.minValue || 0,
    appliedValue: {
      min: this.props.minValue,
      max: this.props.maxValue
    }
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false);

    // shameful hack - style container to prevent break in flow
    const containerElement = this._element.current.parentElement.parentElement;

    setTimeout(() => {
      const width = containerElement.clientWidth;
      containerElement.style.minWidth = width + 0 + 'px';
    }, 2000);
  }

  componentDidCatch(error) {
    console.log(error);
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (this._element.current.contains(e.target)) {
      return;
    }

    if (e.target.id === 'clear-filter-btn') {
      this.setState({
        removable: false
      });
    }

    this.setState({
      expanded: false
    });
  }

  isRemovable = () => {
    const { removable } = this.state;

    return removable;
  }

  handleSetExpand = (value, e) => {
    e.stopPropagation();

    if (value) {
      let { min, max } = this.state.appliedValue;

      this.setState({
        value: { min, max }
      });
    }

    this.setState({ expanded: value });
  }

  handleApply = (value, e) => {
    e.stopPropagation();
    const { min, max } = this.state.value;

    this.setState({
      expanded: value,
      removable: true,
      appliedValue: { min, max }
    });

    this.props.onApply([min, max], this.props.filterKey);
  }

  handleRemove = (e) => {
    e.stopPropagation();
    const { onApply, onRemove, filterKey, minValue, maxValue } = this.props;

    this.setState({
      value: null,
      removable: false,
      appliedValue: {
        min: minValue,
        max: maxValue
      }
    });

    onApply(null, filterKey);

    if (onRemove) {
      onRemove(null, filterKey);
    }
  }

  onChange = (values) => {
    const processedValues = { ...values };

    if (processedValues.max > this.props.maxValue) {
      processedValues.max = this.props.maxValue;
    }

    if (processedValues.min < this.props.minValue) {
      processedValues.min = this.props.minValue;
    }

    this.setState({ value: processedValues });
  };


  render() {
    const { label, direction } = this.props;
    const { expanded, minValue, maxValue } = this.state;

    return (
      <Fragment>
        {!expanded &&
          <div ref={this._element} id={idFormatter(label)}>
            <div className={`filter-chip ${this.isRemovable() ? 'removable' : ''}`} onClick={(e) => this.handleSetExpand(true, e)}>
              <div className="filter-chip-header">
                <span className="chip-label">
                  {`${idFormatter(label)} ${this.isRemovable() ? '(1)' : ''}`}
                </span>
                <Fragment>
                  {this.isRemovable() &&
                    <button id={`${idFormatter(label)}-filter-cancel`} className="chip-remove-button" onClick={(e) => this.handleRemove(e)}>
                      <FontIcon>cancel</FontIcon>
                    </button>
                  }
                  {!expanded && !this.isRemovable() &&
                    <span id={`${idFormatter(label)}-filter-drop-down`} className="chip-icon">
                      <FontIcon>arrow_drop_down</FontIcon>
                    </span>
                  }
                </Fragment>
              </div>
            </div>
          </div>
        }
        {expanded &&
          <div ref={this._element} id={idFormatter(label)}>
            <div className={`filter-chip expanded expand-${direction}`}>
              <div className="filter-chip-header">
                <span className="chip-label">
                  {label}
                </span>
                <button id={`${idFormatter(label)}-filter-close-chip`} className="chip-close-icon" onClick={(e) => this.handleSetExpand(false, e)}>
                  <FontIcon>close</FontIcon>
                </button>
              </div>
              <div className="filter-chip-dropdown">
                <br />
                <br />
                <div className="input-range-wrapper">
                  <InputRange
                    id={`${idFormatter(label)}-filter-slider`}
                    maxValue={maxValue || 0}
                    minValue={minValue || 0}
                    step={1}
                    value={this.state.value || { min: minValue, max: maxValue }}
                    onChange={this.onChange}
                    formatLabel={this.props.formatLabel}
                  />
                </div>
                <br />
                <div className="row end-xs">
                  <div className="col-xs">
                    <Button id={`${idFormatter(label)}-filter-apply`} flat secondary swapTheming onClick={(e) => this.handleApply(false, e)}>Apply</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}
