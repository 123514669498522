import React, { createContext, useState } from 'react';

const DailyEventContext = createContext([{}, () => {}]);

const DailyEventContextProvider = ({ children, clearedFilters, events, filters, filterState, setFilterState }) => {
  const [view, setView] = useState('card');

  return (
    <DailyEventContext.Provider value={{ clearedFilters, events, filters, filterState, setFilterState, setView, view }}>
      {children}
    </DailyEventContext.Provider>
  );
};

export { DailyEventContext, DailyEventContextProvider };
