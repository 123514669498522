import React from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart,
  Chart,
  withHighcharts,
  LineSeries,
  YAxis,
  XAxis,
  PlotLine,
  Tooltip
} from 'react-jsx-highcharts';
import './AvgChart.scss';

const AvgChart = ({ data, total }) => {
  const markerOptions = {
    enabled: false
  };

  return (
    <>
      <HighchartsChart chart={{ height: '220' }} className="line-chart">
        <Chart />
        <Tooltip backgroundColor="white" xDateFormat="%A, %b %e, %l:%M:%S" />
        <XAxis
          type="datetime"
          gridLineWidth="0px"
          labels={{
            format: '{value:%l:%M}',
            style: {
              color: '#666666',
              'font-size': '14px'
            }
          }}
        />
        <YAxis
          gridLineWidth="0px"
          labels={{
            enabled: false
          }}
        >
          <PlotLine
            dashStyle="dash"
            color="#D6D6D6"
            width="2"
            value={total}
            label={{
              text: 'avg',
              align: 'right',
              y: 4,
              style: {
                color: '#BEBEBE',
                fontSize: '16px'
              }
            }}
          />
          <LineSeries color="black" marker={markerOptions} data={data[0].data} name={data[0].name} />
        </YAxis>
      </HighchartsChart>
      <div className="avg-chart-legend-container">
        <h2>{total}</h2>
        <p>Average Transactions Per Minute</p>
      </div>
    </>
  );
};
export default withHighcharts(AvgChart, Highcharts);
