import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAverageByEvents, clearAverageData } from '../../actions/average.action';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { NoDataMessage } from '../../components';
import { isNotEmpty, sendPageTitle } from '../../utils';
import { getItem } from '../../utils/sessionStorage';
import AverageCards from './AverageCards';

const Average = ({ clearAverageData, data = {}, getAverageByEvents, isLoading = true }) => {
  useEffect(() => {
    sendPageTitle('Average');
    getAverageByEvents(getItem('selectedEvents'));

    return () => clearAverageData();
  }, [clearAverageData, getAverageByEvents]);

  return (
    <>
      {!isLoading && (
        <div id="average" className="row">
          {isNotEmpty(data) ? <AverageCards data={data} /> : !isLoading && <NoDataMessage />}
        </div>
      )}
      <LoadingSpinner show={isLoading} delay={1000} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state.average.data,
    isError: state.average.isError,
    isLoading: state.average.isLoading
  };
};

export default connect(mapStateToProps, {
  getAverageByEvents,
  clearAverageData
})(Average);
