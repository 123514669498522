import React, { Fragment } from 'react';
import { DonutChart } from '../../components';
import { EmptyChart } from '../../components';
import { numberWithCommas, percentFormat, isNotEmpty } from '../../utils';

export const RedeemedPrepaids = props => {
  const { data } = props;

  const dataAvailable = isNotEmpty(data) && (data.total.value > 0);

  let formattedData = [];

  const prepaidColors = [
    '#673AB7', // purple
    '#E8DBFF' // light purple
  ];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];
      for (let x in data) {
        if (data.hasOwnProperty(x) && x !== 'displayName' && x !== 'percent') {
          formattedArray.push({
            name: data[x].label,
            y: parseFloat(data[x].value.toFixed(1)),
            color: undefined,
            selected: true
          });
        }
      }

      return formattedArray;
    })();
  }

  return (
    <Fragment>
      {dataAvailable ? (
        <DonutChart
          total={percentFormat(data.percent.value)}
          subtitle="Redeemed"
          name="Prepaids"
          colors={prepaidColors}
          data={formattedData}
          legendData={[
            {
              name: 'Redeemed',
              y: `${numberWithCommas(data.total.value)} / ${numberWithCommas(data.sold.value)}`,
              color: prepaidColors[0]
            }
          ]}
        />
      ) : (
        <EmptyChart />
      )}
    </Fragment>
  );
};
