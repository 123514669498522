import './TabNavigation.scss';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import * as routes from '../../constants/routes';

const TabNavigation = ({ isDailyEvent, id = '', pathname = '' }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const defaultTab = pathname.split('/')[3];
  const {
    AVERAGE,
    CASHIERS,
    DAILYEVENT,
    INVENTORY,
    METRICS,
    OVERVIEW,
    RANKINGS,
    ROLLUPS,
    SUMMATION,
    TRANSACTIONS
  } = routes;

  const rollupConfig = [
    { name: 'Rankings', path: `${ROLLUPS}${METRICS}${RANKINGS}` },
    { name: 'Average', path: `${ROLLUPS}${METRICS}${AVERAGE}` },
    { name: 'Summation', path: `${ROLLUPS}${METRICS}${SUMMATION}` }
  ];

  const singleEventConfig = [
    { name: 'Overview', path: `${DAILYEVENT}${METRICS}${OVERVIEW}/${id}` },
    { name: 'Inventory', path: `${DAILYEVENT}${METRICS}${INVENTORY}/${id}` },
    { name: 'Cashiers', path: `${DAILYEVENT}${METRICS}${CASHIERS}/${id}` },
    {
      name: 'Transactions',
      path: `${DAILYEVENT}${METRICS}${TRANSACTIONS}/${id}`
    }
  ];

  const navConfig = isDailyEvent ? singleEventConfig : rollupConfig;

  useEffect(() => {
    function findDefaultTab() {
      return navConfig.findIndex(item => item.path.includes(defaultTab));
    }

    setActiveTabIndex(findDefaultTab());
  }, [navConfig, defaultTab]);

  function handleUpdateActiveTab(index) {
    setActiveTabIndex(index);
  }

  return (
    <div className="tabs-wrapper">
      <nav className="tab-navigation">
        <div className="tab-navigation-menu">
          <div className={`link-container active-${activeTabIndex}`}>
            {navConfig.map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                onClick={() => handleUpdateActiveTab(index)}
                activeClassName="active"
              >
                {item.name}
                <span className="bottom-border" />
              </NavLink>
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TabNavigation;
