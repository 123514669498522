import React, { useEffect, useMemo, useState } from 'react';
import './DailyEvent.scss';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { NoDataMessage } from '../../components';
import { sendPageTitle } from '../../utils';
import { DailyEventContextProvider } from './DailyEventContext';
import DailyEventActionBar from './DailyEventActionBar';
import DailyEventList from './DailyEventList';
import { getEventsByUser, clearEventsData } from '../../actions/events.action';
import { connect } from 'react-redux';

const DailyEvent = ({ clearEventsData, events = [], filters = {}, getEvents, isError = false, isLoading = true }) => {
  const clearedFilters = {
    limit: 100,
    offset: 0,
    landmarkIdFilter: [],
    productIdFilter: null,
    search: '',
    timeFilter: null
  };

  const [filterState, setFilterState] = useState(clearedFilters);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  useMemo(() => {
    if (initialDataLoaded) {
      getEvents(filterState);
    }
  }, [filterState, getEvents, initialDataLoaded]);

  useEffect(() => {
    if (!isLoading && !initialDataLoaded) {
      setInitialDataLoaded(true);
    }
  }, [isLoading, initialDataLoaded]);

  useEffect(() => {
    if (!initialDataLoaded) {
      sendPageTitle('Daily Event');
      clearEventsData();
      getEvents(clearedFilters);
      setInitialDataLoaded(true);
    }
  }, [clearEventsData, clearedFilters, getEvents, initialDataLoaded]);

  return (
    <DailyEventContextProvider
      clearedFilters={clearedFilters}
      events={events}
      filters={filters}
      filterState={filterState}
      setFilterState={setFilterState}
    >
      {isError ? (
        <NoDataMessage />
      ) : (
        <div id="daily-event" className="daily-event-wrapper">
          <DailyEventActionBar />
          <DailyEventList />
        </div>
      )}
      <LoadingSpinner show={isLoading} delay={1000} />
    </DailyEventContextProvider>
  );
};

const mapStateToProps = state => ({
  events: state.events.data,
  filters: state.events.filters,
  isError: state.events.isError,
  isLoading: state.events.isLoading
});

export default connect(mapStateToProps, {
  getEvents: getEventsByUser,
  clearEventsData
})(DailyEvent);
