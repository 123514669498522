import React from 'react';
import { SearchIcon } from '../../assets/react-icons';
import './EmptyChart.scss';

const EmptyChart = () => (
  <div className="empty-chart-container">
    <SearchIcon color="#BBBBBB" height="70px" />
    <div className="empty-chart-text">
      No data matches
      <br />
      these parameters
    </div>
  </div>
);

export default EmptyChart;
