import * as types from './ActionTypes';
import { create } from 'axios';
import compress from 'graphql-query-compress';
import { loader } from 'graphql.macro';

const rankingsQuery = loader('./queries/rankingsQuery.gql');

const ajax = create({
  timeout: 100000,
  contentType: 'application/json',
  withCredentials: true
});

export const getRankingsByEvents = (eventIds) => dispatch => {
  const variables = {
    eventIds: eventIds,
    limit: eventIds.length
  };

  dispatch({
    type: types.GET_RANKINGS_PENDING
  });

  ajax
    .get(process.env.REACT_APP_ANALYTICS_SERVER, {
      method: 'GET',
      params: {
        query: compress(rankingsQuery.loc.source.body),
        variables: variables
      }
    })
    .then(resp => {
      dispatch({
        type: types.GET_RANKINGS_SUCCESS,
        payload: resp.data.data.EventAnalytics.event.aggregations.rankings
      });
    })
    .catch(error => {
      dispatch({
        type: types.GET_RANKINGS_REJECTED,
        payload: error
      });
    });
};

export const clearRankingsData = () => dispatch => {
  dispatch({
    type: types.CLEAR_RANKINGS_DATA
  });
};
