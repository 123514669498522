import React from 'react';
import { isNotEmpty, formatFilterValue, createFilterList } from '../../utils';
import { FilterBar, FilterChip, FilterChipSlider, ViewToggle } from '../../components';

const InventoryActionBar = ({ applyFilter, filters = {}, setView, view }) => (
  <section className="top-action-bar">
    <div className="col-xs">
      {process.env.REACT_APP_FILTERS_ENABLED && isNotEmpty(filters) && (
        <FilterBar>
          {isNotEmpty(filters.inventory) && (
            <FilterChip
              id="inventory-location-filter"
              data={createFilterList(filters.inventory, 'name')}
              label="Lots"
              filterKey="inventoryIdFilter"
              onApply={applyFilter}
            />
          )}
          {isNotEmpty(filters.products) && filters.products.length > 1 && (
            <FilterChip
              id="inventory-merchandise-filter"
              data={createFilterList(filters.products, 'name')}
              label="Products"
              filterKey="productIdFilter"
              onApply={applyFilter}
            />
          )}
          {isNotEmpty(filters.revenue) && filters.revenue[1] !== null && (
            <FilterChipSlider
              id="inventory-revenue-filter"
              label="Revenue"
              filterKey="revenueFilter"
              formatLabel={value => formatFilterValue(value, '$')}
              step={5}
              minValue={filters.revenue[0] || 0}
              maxValue={filters.revenue[1] || 0}
              onApply={applyFilter}
            />
          )}
          {isNotEmpty(filters.utilization) && filters.utilization[1] !== null && (
            <FilterChipSlider
              id="inventory-utilization-filter"
              label="Utilization"
              step={5}
              formatLabel={value => formatFilterValue(value, '%', 'right')}
              filterKey="utilizationFilter"
              minValue={filters.utilization[0] || 0}
              maxValue={filters.utilization[1] || 0}
              onApply={applyFilter}
            />
          )}
        </FilterBar>
      )}
    </div>
    <div className="row row-clear middle-xs end-xs">
      <div className="action-container">
        <ViewToggle toggle={setView} view={view} />
      </div>
    </div>
  </section>
);

export default InventoryActionBar;
