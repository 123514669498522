import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ClearFiltersMessage, InventoryCard, OverviewDialog, Table } from '../../components';
import { sendPageTitle } from '../../utils';
import { getInventoryByEvent, clearInventoryData } from '../../actions/inventory.action';
import InventoryActionBar from './InventoryActionBar';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { useRouteMatch } from 'react-router-dom';

const lotLabelKeys = [
  { label: 'Name', key: 'name', sortable: true },
  {
    label: 'Total Transactions',
    key: 'metrics.inventoryOverview.transactions',
    sortable: true
  },
  {
    label: 'Total Failed Transactions',
    key: 'metrics.inventoryOverview.failedTransactions',
    sortable: true
  },
  {
    label: 'Total Revenue',
    key: 'metrics.inventoryOverview.revenue',
    sortable: true
  },
  {
    label: 'Utilization',
    key: 'metrics.inventoryOverview.utilization',
    sortable: true
  },
  {
    label: 'Reprints',
    key: 'metrics.inventoryOverview.reprintsTotal',
    sortable: true
  },
  {
    label: 'Prepaid',
    key: 'metrics.carsParkedByType.prepaid.value',
    sortable: true
  },
  {
    label: 'Exemptions',
    key: 'metrics.exemptionTypes.total.value',
    sortable: true
  }
];

const merchLabelKeys = [
  { label: 'Name', key: 'name', sortable: true },
  { label: 'Total Transactions', key: 'transactions', sortable: true },
  { label: 'Total Revenue', key: 'revenue', sortable: true },
  { label: 'Inventory Remaining', key: 'inventoryRemaining', sortable: true }
];

const Inventory = ({ clearInventoryData, data = [], filters = {}, getInventoryByEvent, isLoading = true }) => {
  const match = useRouteMatch();
  const clearedDialog = {
    pageX: null,
    pageY: null,
    dialogData: {},
    dialogVisible: false
  };

  const clearedFilters = {
    inventoryIdFilter: null,
    productIdFilter: null,
    reprintFilter: null,
    revenueFilter: null,
    utilizationFilter: null
  };

  const [dialogState, setDialogState] = useState(clearedDialog);
  const [filterState, setFilterState] = useState(clearedFilters);
  const [view, setView] = useState('card');

  const loadData = useMemo(() => getInventoryByEvent(match.params.id, filterState), [
    filterState,
    getInventoryByEvent,
    match
  ]);

  useEffect(() => {
    sendPageTitle('Inventory');

    return () => clearInventoryData();
  }, [clearInventoryData, loadData]);

  useEffect(() => loadData, [filterState, loadData]);

  const showDialog = (e, dialogData) => {
    let { pageX, pageY } = e;
    if (e.changedTouches) {
      pageX = e.changedTouches[0].pageX;
      pageY = e.changedTouches[0].pageY;
    }

    setDialogState({ dialogData, dialogVisible: true, pageX, pageY });
  };

  const hideDialog = () => setDialogState({ ...dialogState, dialogVisible: false });

  function applyFilter(value, key) {
    return setFilterState({ ...filterState, [key]: value });
  }

  const clearAllFilters = () => setFilterState(clearedFilters);

  return (
    <>
      <div id="inventory">
        <InventoryActionBar applyFilter={applyFilter} filters={filters} setView={setView} view={view} />
        <section className="content-container">
          {view === 'list' ? (
            <Table data={data} labelKeys={lotLabelKeys} onRowClick={showDialog} sortable title="Inventory" />
          ) : (
            <div className="row row-clear">
              {data &&
                data.map((item, index) => (
                  <div
                    className="col-xs-12 col-sm-6 col-md-3"
                    id={`inventory-card-${index}`}
                    key={index}
                    onClick={e => showDialog(e, item)}
                  >
                    <InventoryCard item={item} labels={['Utilization', 'Transactions', 'Revenue']} />
                  </div>
                ))}
            </div>
          )}
        </section>
        {data.merchandise && (
          <section className="top-action-bar">
            <div className="row row-clear end-xs">
              <div className="section-title col-xs">
                <h4>Merchandise</h4>
              </div>
            </div>
          </section>
        )}
        {data.merchandise && view === 'list' ? (
          <section className="content-container">
            <Table
              data={data.merchandise}
              labelKeys={merchLabelKeys}
              onRowClick={showDialog}
              sortable
              title="Inventory"
            />
          </section>
        ) : (
          <>
            {data.merchandise && (
              <section className="content-container">
                <div className="row row-clear">
                  {data.merchandise.map((item, index) => (
                    <div className="col-xs-12 col-sm-6 col-md-3" key={index} onClick={e => showDialog(e, item)}>
                      <InventoryCard item={item} labels={['Inventory Remaining', 'Transactions', 'Revenue']} />
                    </div>
                  ))}
                </div>
              </section>
            )}
          </>
        )}
      </div>
      <OverviewDialog
        visible={dialogState.dialogVisible}
        pageX={dialogState.pageX}
        pageY={dialogState.pageY}
        onHide={hideDialog}
        data={dialogState.dialogData}
        title={`Inventory / ${dialogState.dialogData.name} `}
      />
      <LoadingSpinner show={isLoading} delay={1000} />
      {!isLoading && data.length <= 0 && <ClearFiltersMessage clearAllFilters={clearAllFilters} />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state.inventory.data,
    filters: state.inventory.filters,
    isError: state.inventory.isError,
    isLoading: state.inventory.isLoading
  };
};

export default connect(mapStateToProps, {
  getInventoryByEvent,
  clearInventoryData
})(Inventory);
