import * as types from './ActionTypes';
import axios, { create } from 'axios';
import compress from 'graphql-query-compress';
import fileDownload from 'js-file-download';
import { loader } from 'graphql.macro';

const csvDownloadQuery = loader('./queries/csvDownloadQuery.gql');

const ajax = create({
  timeout: 100000,
  contentType: 'application/json',
  withCredentials: true
});

export const getTransactionCSV = (eventId, params) => dispatch => {
  dispatch({
    type: types.GET_TRANSACTIONS_CSV_PENDING
  });

  const variables = {
    ...params,
    eventIds: [eventId]
  };

  ajax
    .post(process.env.REACT_APP_ANALYTICS_SERVER_CSV, {
      query: compress(csvDownloadQuery.loc.source.body),
      operationName: 'EventAnalytics',
      variables: variables
    })
    .then(response => {
      axios.get(response.data.file, { withCredentials: false }).then(resp => {
        fileDownload(resp.data, response.data.filename + '.csv');
      });

      dispatch({
        type: types.GET_TRANSACTIONS_CSV_SUCCESS,
        payload: true
      });
    })
    .catch(error => {
      console.log(error);
      dispatch({
        type: types.GET_TRANSACTIONS_CSV_REJECTED,
        payload: error
      });
    });
};
