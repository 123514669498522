import * as types from './ActionTypes';
import { create } from 'axios';
import compress from 'graphql-query-compress';
import { loader } from 'graphql.macro';

const summationQuery = loader('./queries/summationQuery.gql');

const ajax = create({
  timeout: 100000,
  contentType: 'application/json',
  withCredentials: true
});

export const getSummationByEvents = (eventIds) => dispatch => {
  const variables = {
    eventIds: eventIds,
    limit: eventIds.length
  };

  dispatch({
    type: types.GET_SUMMATION_PENDING
  });

  ajax
    .get(process.env.REACT_APP_ANALYTICS_SERVER, {
      method: 'GET',
      params: {
        query: compress(summationQuery.loc.source.body),
        variables: variables
      }
    })
    .then(resp => {
      dispatch({
        type: types.GET_SUMMATION_SUCCESS,
        payload: resp.data.data.EventAnalytics.event.aggregations.summation
      });
    })
    .catch(error => {
      dispatch({
        type: types.GET_SUMMATION_REJECTED,
        payload: error
      });
    });
};

export const clearSummationData = () => dispatch => {
  dispatch({
    type: types.CLEAR_SUMMATION_DATA
  });
};
