import React, { Fragment } from 'react';
import { DonutChart } from '../../components';
import { EmptyChart } from '../../components';
import { CHART_COLORS, colorIndexOf, kFormatter, currencyFormat } from '../../utils/chartconfig';

export const CreditRevenue = props => {
  const { data } = props;
  const dataAvailable = data.totalRevenue.value > 0;

  let formattedData = [];
  let legendData = [];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];
      for (let x in data) {
        if (data.hasOwnProperty(x) && x !== 'displayName' && x !== 'totalRevenue') {
          formattedArray.push({
            name: data[x].label ? data[x].label : 'Unknown Type',
            y: data[x].value,
            color: CHART_COLORS[colorIndexOf(data[x].label)],
            selected: true
          });
        }
      }
      return formattedArray;
    })();

    legendData = (() => {
      let formattedArray = [];
      for (let x in data) {
        if (data.hasOwnProperty(x) && x !== 'displayName' && x !== 'totalRevenue') {
          formattedArray.push({
            name: data[x].label ? data[x].label : 'Unknown Type',
            y: currencyFormat(data[x].value),
            color: CHART_COLORS[colorIndexOf(data[x].label)]
          });
        }
      }
      return formattedArray;
    })();
  }

  return (
    <Fragment>
      {dataAvailable ? (
        <DonutChart
          total={`$${kFormatter(data.totalRevenue.value)}`}
          legendData={legendData}
          name="Revenue"
          subtitle={data.displayName}
          data={formattedData}
        />
      ) : (
        <EmptyChart />
      )}
    </Fragment>
  );
};
