import * as types from '../actions/ActionTypes';

const INITIAL_STATE = {
  isLoading: true,
  isError: null,
  data: []
};

export function rankingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_RANKINGS_PENDING: {
      return {
        ...state,
        isError: null,
        isLoading: true
      };
    }
    case types.GET_RANKINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isError: null,
        isLoading: false
      };
    case types.GET_RANKINGS_REJECTED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };
    case types.CLEAR_RANKINGS_DATA:
      return {
        ...state,
        data: [],
        isError: null,
        isLoading: false
      };
    default: return state;
  }
}
