import './InventoryCard.scss';
import React from 'react';
import { Card } from 'react-md';

const ListCard = ({ children, item = {} }) => {
  const { first_name = '', last_name = '', lots = [] } = item;

  return (
    <Card className="inventory-card">
      <div className="top-title" >
        <div className="card-title"><h4>{`${first_name} ${last_name}`}</h4></div>
        <div className="card-subtitle">{typeof lots === 'object' && lots.map(x => x.name).join(' / ')}</div>
      </div>
      {children}
    </Card>
  );
};

export default ListCard;
