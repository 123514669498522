import './ChartLegend.scss';
import React from 'react';
import { CHART_COLORS, colorIndexOf } from '../../utils/chartconfig';

const ChartLegend = ({ breakdown, data, layout, total, type }) => {
  const color = series => {
    let { name } = series;

    return series.color ? series.color : series.name && CHART_COLORS[colorIndexOf(name)];
  };
  const chartLegend = data => {
    const dataArr = [...data];

    return dataArr.map((series, i) => {
      const legendColor = () => {
        if (layout === 'breakdown') {
          if (series.entry === 'accepted') {
            if (i === 0) {
              return `rgba(102,187,106,1)`;
            } else if (i === 1) {
              return 'rgba(102,187,106,0.6)';
            } else if (i === 2) {
              return 'rgba(102,187,106,0.4)';
            }
          } else if (series.entry === 'rejected') {
            if (i === 0) {
              return `rgba(229, 57, 53,1)`;
            } else if (i === 1) {
              return `rgba(229, 57, 53,0.6)`;
            } else if (i === 2) {
              return `rgba(229, 57, 53,0.4)`;
            }
          }
        } else {
          return `${color(series)}`;
        }
      };

      return (
        <div
          className="chart-legend-item"
          key={i}
          style={{
            borderLeft: `${legendColor()} 4px solid`,
            width: layout === 'row' && '40%'
          }}
        >
          <div className="chart-legend-item-data">
            {(type === 'donut' ? series.y : total ? total : series.y).toLocaleString()}
          </div>
          <div className="chart-legend-item-name">{series.name}</div>
        </div>
      );
    });
  };

  const prepaidBreakdownLegend = () => {
    const { accepted } = breakdown;
    const { rejected } = breakdown;
    const breakdownSection = type => {
      let dataArr = type === 'accepted' ? accepted : rejected;

      return (
        <div className="breakdown-section">
          <div
            className="chart-legend-item"
            style={{
              borderLeft: type === 'accepted' ? 'rgba(102,187,106,1) 4px solid' : 'rgba(229, 57, 53,1) 4px solid'
            }}
          >
            <div className="chart-legend-item-data">
              {(dataArr && dataArr.reduce((a, b) => ({ y: a.y + b.y })).y).toLocaleString()}
            </div>
            <div className="chart-legend-item-name">{type[0].toUpperCase() + type.slice(1)}</div>
          </div>
          <div className="breakdown-subset">{chartLegend(dataArr)}</div>
        </div>
      );
    };

    return (
      <div className="breakdown-legend">
        {accepted.length > 0 && breakdownSection('accepted')}
        {rejected.length > 0 && breakdownSection('rejected')}
      </div>
    );
  };

  return (
    <div>
      <div className="chart-legend-container">
        {layout === 'breakdown' ? prepaidBreakdownLegend() : chartLegend(data)}
      </div>
    </div>
  );
};

export default ChartLegend;
