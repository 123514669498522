import './OverviewChartCard.scss';
import React, { Children, cloneElement } from 'react';
import { Card } from 'react-md';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const OverviewChartCard = ({ children, title, data }) => (
  <Card className="chart-card">
    <ErrorBoundary>
      <h4 className="chart-card-title">{title}</h4>
      <div className="chart-card-content">
        {Children.map(children, child => child && cloneElement(child, { title, data }))}
      </div>
    </ErrorBoundary>
  </Card>
);

export default OverviewChartCard;
