import './FilterChip.scss';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontIcon, Button, TextField } from 'react-md';
import DatePicker from 'react-datepicker';
import { idFormatter } from '../../utils';
import moment from 'moment';

const popperModifiers = {
  flip: {
    behavior: ['bottom'] // don't allow it to flip to be above
  },
  preventOverflow: {
    enabled: true,
    escapeWithReference: false, // force popper to stay in viewport
    boundariesElement: 'scrollParent'
  }
};

const Input = ({ id, placeholder, onClick, value }) => (
  <TextField
    id={id}
    placeholder={placeholder}
    rightIcon={<FontIcon>date_range</FontIcon>}
    fullWidth
    onChange={v => console.log(v)}
    onClick={onClick}
    value={value}
  />
);

export class FilterChipDateRange extends Component {
  static get propTypes() {
    return {
      direction: PropTypes.string,
      label: PropTypes.string.isRequired
    };
  }

  static defaultProps = {
    direction: 'right'
  };

  constructor(props) {
    super(props);
    this._element = React.createRef();
  }

  state = {
    expanded: false,
    removable: false,
    value: null,
    dateFrom: moment.utc(this.props.startDate, 'X').toDate(),
    dateTo: moment.utc(this.props.endDate, 'X').toDate()
  };

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false);

    // shameful hack - style container to prevent break in flow
    const containerElement = this._element.current.parentElement.parentElement;

    setTimeout(() => {
      const width = containerElement.clientWidth;
      containerElement.style.minWidth = width + 0 + 'px';
    }, 2000);
  };

  componentDidCatch(error) {
    console.log(error);
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false);
  };

  handleClick = e => {
    if (this._element.current.contains(e.target)) {
      return;
    }

    this.setState({
      expanded: false
    });
  };

  isRemovable = () => {
    const { removable } = this.state;
    return removable;
  };

  handleSetExpand = (value, e) => {
    e.stopPropagation();
    this.setState({ expanded: value });
  };

  handleApply = (value, e) => {
    e.stopPropagation();
    const { dateFrom, dateTo } = this.state;
    const { onApply, filterKey } = this.props;
    const formatDate = date => new Intl.DateTimeFormat('en-US', {}).format(date);
    const startofDayTimeStamp = new Date(`${formatDate(dateFrom)} 00:00:00`).getTime() / 1000;
    const endOfDayTimeStamp = new Date(`${formatDate(dateTo)} 23:59:59`).getTime() / 1000;

    this.setState({
      expanded: value,
      removable: true
    });

    onApply([startofDayTimeStamp, endOfDayTimeStamp], filterKey);
  };

  handleRemove = e => {
    e.stopPropagation();
    this.setState({
      dateFrom: null,
      dateTo: null,
      removable: false
    });

    this.props.onRemove(this.props.filterKey);
  };

  handleChangeFrom = date => {
    this.setState({
      dateFrom: date
    });
  };

  handleChangeTo = date => {
    this.setState({
      dateTo: date
    });
  };

  render() {
    const { label, direction } = this.props;
    const { expanded, dateFrom, dateTo } = this.state;

    return (
      <Fragment>
        {!expanded && (
          <div ref={this._element} id={idFormatter(label)}>
            <div
              className={`filter-chip ${this.isRemovable() ? 'removable' : ''}`}
              onClick={e => this.handleSetExpand(true, e)}
            >
              <div className="filter-chip-header">
                <span className="chip-label">{`${label} ${this.isRemovable() ? '(1)' : ''}`}</span>
                <Fragment>
                  {this.isRemovable() && (
                    <button className="chip-remove-button" onClick={e => this.handleRemove(e)}>
                      <FontIcon>cancel</FontIcon>
                    </button>
                  )}
                  {!expanded && !this.isRemovable() && (
                    <span className="chip-icon">
                      <FontIcon>arrow_drop_down</FontIcon>
                    </span>
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        )}
        {expanded && (
          <div ref={this._element} id={idFormatter(label)}>
            <div className={`filter-chip expanded expand-${direction}`}>
              <div className="filter-chip-header">
                <span className="chip-label">{label}</span>
                <button className="chip-close-icon" onClick={e => this.handleSetExpand(false, e)}>
                  <FontIcon>close</FontIcon>
                </button>
              </div>
              <div className="filter-chip-dropdown date-range">
                <div className="row collapse-sides form-group-section">
                  <div className="col-xs-5">
                    <DatePicker
                      customInput={<Input />}
                      endDate={dateTo}
                      id="dateFrom"
                      maxDate={dateTo}
                      onChange={this.handleChangeFrom}
                      placeholderText="Start Date"
                      popperModifiers={popperModifiers}
                      popperPlacement="bottom"
                      selected={dateFrom}
                      selectsStart
                      startDate={dateFrom}
                    />
                  </div>
                  <div className="col-xs-2 center">to</div>
                  <div className="col-xs-5">
                    <DatePicker
                      customInput={<Input />}
                      endDate={dateTo}
                      id="dateTo"
                      minDate={dateFrom}
                      onChange={this.handleChangeTo}
                      placeholderText="End Date"
                      popperModifiers={popperModifiers}
                      popperPlacement="bottom"
                      selected={dateTo}
                      selectsEnd
                      startDate={dateFrom}
                    />
                  </div>
                </div>
                <br />
                <div className="row end-xs">
                  <div className="col-xs">
                    <Button flat secondary swapTheming onClick={e => this.handleApply(false, e)}>
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
