import React from 'react';
import { Card, CardText, FontIcon } from 'react-md';
import americanFootballImg from './americanfootball.png';
import otherImg from './other.png';
import concertImg from './concert.png';
import TextTruncate from 'react-text-truncate';
import { makeTimeReadable } from '../../utils';

const EventCard = ({
  event = '',
  eventId = '',
  index,
  landmark = '',
  list,
  onClick,
  selectable,
  selectedIds = [],
  tag,
  timestamp,
  timezone
}) => {
  const checkForSelected = id => selectedIds.includes(id);

  const getImage = tag => {
    if (tag !== '') {
      const images = {
        'Sporting Event': () => americanFootballImg,
        'Other Event': () => otherImg,
        'Musical Event': () => concertImg
      };
      return (images[tag] || otherImg)();
    } else {
      return otherImg;
    }
  };

  function handleClick(e, id, index) {
    onClick(e, { id }, index);
  }

  const { date, time, tzcode } = makeTimeReadable(timestamp, timezone);

  return (
    <Card
      onClick={e => handleClick(e, eventId, index)}
      className={`event-card ${selectable && checkForSelected(eventId) ? 'active' : ''} ${list ? 'list' : ''}`}
    >
      <div className="event-image-container">
        <img alt="event" src={getImage(tag)} />
      </div>
      <CardText>
        {event && (
          <div className="event-title">
            <FontIcon>event</FontIcon>
            <h5 title={event}>
              <TextTruncate line={1} text={event} />
            </h5>
          </div>
        )}
        {landmark && (
          <span title={landmark} className="details">
            <TextTruncate line={1} text={landmark} />
          </span>
        )}
        {timestamp && (
          <div className="timestamp">
            <span title={`${date}`} className="details inline">
              {date}
            </span>
            <span title={`${time} ${tzcode}`} className="details inline">
              {time} {tzcode}
            </span>
          </div>
        )}
        {selectable && checkForSelected(eventId) && (
          <span className="selected-indicator">
            <FontIcon>check_circle</FontIcon>
          </span>
        )}
      </CardText>
    </Card>
  );
};

export default EventCard;
