import React, { Fragment } from 'react';
import { LineChart } from '../../components';
import { orderByKey, timeFormatter } from '../../utils';
import { EmptyChart } from '../../components';

export const Activity = ({ data = {} }) => {
  const dataAvailable = data.timeSeriesData.length > 0;

  let formattedData = [];

  if (dataAvailable) {
    formattedData = orderByKey(data.timeSeriesData, 'timestamp').map(x => {
      const entry = [];
      entry.push(timeFormatter(x.timestamp), x.value);

      return entry;
    });
  }

  return (

    <Fragment>
      {dataAvailable ?
        <LineChart data={[{ name: 'Parked', data: formattedData }]} total={data.totalCarsParked.value} timezone={data.timezone} /> :
        <EmptyChart />
      }
    </Fragment>
  );
};
