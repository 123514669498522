import React, { Fragment } from 'react';
import { Button } from 'react-md';

const LandmarksHeader = ({
  landmarks = [],
  selectedLandmarks = [],
  setView,
  updateSelectedLandmarks,
  view
}) => {
  const toggleView = () => (view === 'map' ? setView('list') : setView('map'));

  const isAllSelected = () => landmarks.length === selectedLandmarks.length;

  function selectAllLandmarks() {
    const updatedLandmarks = isAllSelected()
      ? []
      : landmarks.map(item => item.id);

    return updateSelectedLandmarks(updatedLandmarks);
  }

  return (
    <Fragment>
      <section className="row collapse middle-xs page-title-separator">
        <div className="col-xs-10">
          <h2 className="page-title">Multiple Location Selection</h2>
        </div>
        <div className="col-xs-2">
          <div className="row row-clear middle-xs end-xs">
            <Button
              className="action-button large"
              flat
              iconChildren={view === 'map' ? 'view_list' : 'room'}
              id="view-toggle"
              onClick={toggleView}
            >
              {view === 'map' ? 'List' : 'Map'} View
            </Button>
          </div>
        </div>
      </section>
      <section className="top-action-bar">
        <div className="row collapse middle-xs end-xs">
          <div className="col-xs-12">
            <Button
              flat
              id="select-all-btn"
              onClick={() => selectAllLandmarks()}
              className="button ghost"
            >
              {isAllSelected()
                ? 'Deselect All'
                : `Select All(${landmarks.length})`}
            </Button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default LandmarksHeader;
