export const colorGuide = [
  'Cash',
  'Admitted',
  'Credit',
  'Prepaid',
  'Parked',
  'Exemptions',
  'Rejected',
  'Available',
  'VIP',
  'Reprints',
  'Visa',
  'Mastercard',
  'Amex',
  'Discover',
  'Permits'
];

export const CHART_COLORS = [
  '#8BC34A', //light green
  '#66BB6A', //green
  '#29B6F6', //blue
  '#673AB7', //purple
  '#FF5722', //orange
  '#78909C', //grey
  '#E53935', //red
  '#ECEFF1', //light grey
  '#EFC74B', //yellow
  '#EC407A', //pink
  '#01579B', //navy blue
  '#E53935', //same red
  '#1E88E5', //similar but different blue
  '#FF8A65', //peach
  '#F89AD8' //pink
];
export const bigColorSet = [
  '#01579B',
  '#E53935',
  '#1E88E5',
  '#FF8A65',
  '#60E8B7',
  '#63b598',
  '#ce7d78',
  '#ea9e70',
  '#a48a9e',
  '#c6e1e8',
  '#648177',
  '#0d5ac1',
  '#f205e6',
  '#1c0365',
  '#14a9ad',
  '#4ca2f9',
  '#a4e43f',
  '#d298e2',
  '#6119d0',
  '#d2737d',
  '#c0a43c',
  '#f2510e',
  '#651be6',
  '#79806e',
  '#61da5e',
  '#cd2f00',
  '#9348af',
  '#01ac53',
  '#c5a4fb',
  '#996635',
  '#b11573',
  '#4bb473',
  '#75d89e',
  '#2f3f94',
  '#2f7b99',
  '#da967d',
  '#34891f',
  '#b0d87b',
  '#ca4751',
  '#7e50a8'
];

export const numberWithCommas = x => {
  const num = Number.isInteger(x) ? x : parseFloat(x.toFixed(1));
  return `${num.toLocaleString()}`;
};

export const currencyFormat = x => {
  const num = parseFloat(x.toFixed(0));
  return `$${num.toLocaleString()}`;
};

export function percentFormat(value, numOfDigits) {
  const digits = (numOfDigits === 0 || numOfDigits) ? numOfDigits : (Number.isInteger(value)) ? 0 : 1;
  return parseFloat((value)).toFixed(digits) + '%';
}

export function kFormatter(num, numOfDigits) {
  const digits = (numOfDigits === 0 || numOfDigits) ? numOfDigits : (Number.isInteger(num)) ? 0 : 1;
  return num > 999 && num < 1000000 ? (num / 1000).toFixed(digits) + 'k' : num >= 1000000 ? (num / 1000000).toFixed(digits) + 'mm' : num.toFixed(digits);
}

export const colorIndexOf = name => {
  return colorGuide.indexOf(name);
};
