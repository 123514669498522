import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { eventsReducer } from './events.reducer';
import { inventoryReducer } from './inventory.reducer';
import { cashiersReducer } from './cashiers.reducer';
import { transactionsReducer } from './transactions.reducer';
import { sessionReducer } from './session.reducer';
import { overviewReducer } from './overview.reducer';
import { layoutReducer } from './layout.reducer';
import { csvDownloadReducer } from './csvDownload.reducer';
import { landmarksReducer } from './landmarks.reducer';
import { averageReducer } from './average.reducer';
import { rankingsReducer } from './rankings.reducer';
import { summationReducer } from './summation.reducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  events: eventsReducer,
  landmarks: landmarksReducer,
  inventory: inventoryReducer,
  cashiers: cashiersReducer,
  transactions: transactionsReducer,
  session: sessionReducer,
  overview: overviewReducer,
  layout: layoutReducer,
  csv: csvDownloadReducer,
  average: averageReducer,
  rankings: rankingsReducer,
  summation: summationReducer
});

export default rootReducer;
