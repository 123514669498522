import React, { Component } from 'react';
import { FontIcon } from 'react-md';

export class ThItem extends Component {

  state = {
    ascDirection: true
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  toggleSortDirection = () => {
    this.setState((prevState) => ({ ascDirection: !prevState.ascDirection }));
  }

  handleClick = () => {
    const { onClick, sortKey, sortable } = this.props;
    const direction = this.state.ascDirection ? 'asc' : 'desc';

    if (sortable) {
      onClick(sortKey, direction);
    }

    this.toggleSortDirection();
  }

  render() {

    const { children, activeSortKey, sortKey, sortable } = this.props;
    const { ascDirection } = this.state;

    return (
      <div className="th-item" onClick={this.handleClick}>
        <span>{children}</span>&nbsp;
        {sortable &&
          <span className="th-icon">{(activeSortKey === sortKey) && <FontIcon>{ascDirection ? 'arrow_drop_up' : 'arrow_drop_down'}</FontIcon>}</span>
        }
      </div>
    );
  }
}
