import * as types from '../actions/ActionTypes';
import { isNotEmpty } from '../utils';

const INITIAL_STATE = {
  isLoading: true,
  isError: null,
  data: [],
  filters: {}
};

export function eventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_EVENTS_PENDING: {
      return {
        ...state,
        isLoading: true,
        isError: null
      };
    }
    case types.GET_EVENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.event.records,
        filters: isNotEmpty(state.filters) ? state.filters : action.payload.event.filters,
        isLoading: false,
        isError: null
      };
    case types.GET_EVENTS_REJECTED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };
    case types.CLEAR_EVENTS_DATA:
      return {
        ...state,
        data: [],
        filters: [],
        isError: null,
        isLoading: false
      };
    default: return state;
  }
}
