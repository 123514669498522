import 'flexboxgrid';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import { store, history } from './App/store';
import { Provider } from 'react-redux';
import registerServiceWorker from './registerServiceWorker';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
  gql
} from '@apollo/client';
import { resolvers, typeDefs } from './resolvers';
import { getCookie, getAuthUser } from './utils';

import { ConnectedRouter } from 'connected-react-router';

const cache = new InMemoryCache();
const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    credentials: 'include'
  }),
  cache,
  typeDefs,
  resolvers
});

function writeInitialData() {
  cache.writeQuery({
    query: gql`
      query {
        authUser @client
        searchTerm @client
      }
    `,
    data: {
      authUser:
        getCookie('86553b39') && getCookie('86553b39') !== 'undefined'
          ? getAuthUser()
          : null,
      searchTerm: ''
    }
  });
}

writeInitialData();

const render = () => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root')
  );
};

render();

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}

registerServiceWorker();
