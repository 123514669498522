import React from 'react';
import { Button } from 'react-md';

const NoDataMessage = () => (
  <section className="row center-xs">
    <div className="col-xs-12">
      <h1>
        No reports are available for viewing, or something may have gone wrong.
      </h1>
      <Button flat onClick={() => window.location.reload()} primary swapTheming>
        Reload
      </Button>
    </div>
  </section>
);

export default NoDataMessage;
