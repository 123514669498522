import React, { Fragment } from 'react';
import { DonutChart } from '../../components';
import { bigColorSet, kFormatter } from '../../utils/chartconfig';
import { EmptyChart } from '../../components';

export const PermitTypes = (props) => {
  const { data } = props;
  const dataAvailable = data.total.value > 0;

  let formattedData = [];

  if (dataAvailable) {
    formattedData = data.reasons.map((x, i) => {
      return {
        name: x.label ? x.label : 'Unknown Type',
        y: parseFloat(x.value.toFixed(1)),
        color: bigColorSet[i],
        selected: true
      };
    });
  }

  return (
    <Fragment>
      {
        dataAvailable ?
          <DonutChart
            colors={bigColorSet}
            data={formattedData}
            layout="row"
            name="Permits"
            subtitle="Permits"
            total={kFormatter(data.total.value)}
          />
          :
          <EmptyChart />
      }
    </Fragment>
  );
};
