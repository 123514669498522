import { gql, useQuery } from '@apollo/client';
import { SessionDialog } from '@parkhub/parkhub-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import * as routes from '../constants/routes';
import Home from '../containers/Home/Home';
import Layout from '../containers/Layout/Layout';
import NoMatch from '../containers/NoMatch/NoMatch';
import { checkAuth, getSessionTime, refreshSession, signOut } from '../utils';
import './App.scss';

const GET_AUTH_USER = gql`
  {
    authUser @client
  }
`;

const App = () => {
  const location = useLocation();
  const { pathname = '' } = location;
  const checkTime = useRef(false);
  const [sessionModalVisible, setSessionModalVisible] = useState(false);
  const { data = {}, loading } = useQuery(GET_AUTH_USER);
  const { DAILYEVENT, METRICS, ROLLUPS, ROOT } = routes;

  const hideDialog = () => setSessionModalVisible(false);
  const showDialog = () => setSessionModalVisible(true);

  const startTimeout = useCallback(
    () => {
      refreshSession()
        .then(() => {
          clearInterval(checkTime.current);

          const checkSessionTimeout = () => {
            if (!checkAuth()) {
              signOut();

            } else {
              const minutes = getSessionTime();

              if (minutes > 1440) {
                hideDialog();
                signOut('?expired=true');

              } else if (minutes > 1438 && minutes < 1440) {
                showDialog();
              }
            }
          };

          checkTime.current = setInterval(checkSessionTimeout, 10000);
        });
    }, [],
  );

  const checkLoggedIn = useCallback(() => {
    if (!checkAuth()) {
      window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);
    } else {
      startTimeout();
    }
  }, [startTimeout]);

  useEffect(() => {
    checkLoggedIn();

    return () => clearInterval(checkTime.current);
  }, [checkLoggedIn, pathname]);

  return (
    <>
      {!loading && !!data.authUser && (
        <>
          <Switch location={location}>
            <Route path={`${DAILYEVENT}${METRICS}/*/:id`}>
              <Layout authUser={data.authUser} />
            </Route>
            <Route path={`${ROLLUPS}${METRICS}`}>
              <Layout authUser={data.authUser} />
            </Route>
            <Route path={ROOT}>
              <Home authUser={data.authUser} />
            </Route>
            <Route component={NoMatch} />
          </Switch>
        </>
      )}
      <SessionDialog
        onHide={hideDialog}
        visible={sessionModalVisible}
        onContinue={startTimeout}
        onTimeExpire={() => signOut('?expired=true')}
        onSignOut={signOut}
      />
    </>
  );
};

export default App;
