import React from 'react';
import { Button } from 'react-md';

const ClearFiltersMessage = ({ clearAllFilters }) => (
  <div className="row center-xs">
    <div className="col-xs">
      <h2 className="center no-data-error">No data matches these parameters.</h2>
      <Button id="clear-filter-btn" flat secondary onClick={clearAllFilters}>
        Clear Filters
      </Button>
    </div>
  </div>
);

export default ClearFiltersMessage;
