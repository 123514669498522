import * as types from './ActionTypes';
import { create } from 'axios';
import compress from 'graphql-query-compress';
import { loader } from 'graphql.macro';

const transactionsQuery = loader('./queries/transactionsQuery.gql');

const ajax = create({
  timeout: 100000,
  contentType: 'application/json',
  withCredentials: true
});

export const getTransactionsByEvent = (eventId, params) => dispatch => {
  dispatch({
    type: types.GET_TRANSACTIONS_PENDING
  });

  const variables = {
    ...params,
    eventIds: [eventId],
    sort: params.sortString,
    search: parseInt(params.search, 0)
  };

  ajax
    .get(process.env.REACT_APP_ANALYTICS_SERVER, {
      method: 'GET',
      params: {
        query: compress(transactionsQuery.loc.source.body),
        variables
      }
    })
    .then(resp => {
      dispatch({
        type: types.GET_TRANSACTIONS_SUCCESS,
        payload: resp.data.data.EventAnalytics
      });
    })
    .catch(error => {
      dispatch({
        type: types.GET_TRANSACTIONS_REJECTED,
        payload: error
      });
    });
};

export const clearTransactionData = () => dispatch => {
  dispatch({
    type: types.CLEAR_TRANSACTION_DATA
  });
};
