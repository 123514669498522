import React from 'react';
import './RollupMetrics.scss';

const RollupMetrics = ({ data = [] }) => (
  <div className="rollups-container">
    <div className="rollup-stat-container">
      {data.map(item => (
        <div key={item.label} className="rollup-stat">
          <h2>{item.value}</h2>
          <p>{item.label}</p>
        </div>
      ))}
    </div>
  </div>
);

export default RollupMetrics;
