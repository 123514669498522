import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { getSummationByEvents, clearSummationData } from '../../actions/summation.action';
import { NoDataMessage } from '../../components';
import { isNotEmpty, sendPageTitle } from '../../utils';
import { getItem } from '../../utils/sessionStorage';
import SummationCards from './SummationCards';

const Summation = ({ clearSummationData, data = {}, getSummationByEvents, isLoading }) => {
  useEffect(() => {
    getSummationByEvents(getItem('selectedEvents'));
    sendPageTitle('Summation');

    return () => clearSummationData();
  }, [clearSummationData, getSummationByEvents]);

  return (
    <>
      {!isLoading && (
        <div id="average" className="row">
          {isNotEmpty(data) ? <SummationCards data={data} /> : !isLoading && <NoDataMessage />}
        </div>
      )}
      <LoadingSpinner show={isLoading} delay={1000} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state.summation.data,
    isError: state.summation.isError,
    isLoading: state.summation.isLoading
  };
};

export default connect(mapStateToProps, {
  getSummationByEvents,
  clearSummationData
})(Summation);
