import * as types from './ActionTypes';
import { create } from 'axios';
import compress from 'graphql-query-compress';
import { checkForGetError } from '../utils/';
import { loader } from 'graphql.macro';

const layoutQuery = loader('./queries/layoutQuery.gql');

const ajax = create({
  timeout: 100000,
  contentType: 'application/json',
  withCredentials: true
});

export const getLayoutByEvents = eventIds => dispatch => {
  dispatch({
    type: types.GET_LAYOUT_PENDING
  });

  ajax
    .get(process.env.REACT_APP_ANALYTICS_SERVER, {
      method: 'GET',
      params: {
        query: compress(layoutQuery.loc.source.body),
        variables: {
          eventIds: [...eventIds]
        }
      }
    })
    .then(resp => {
      checkForGetError(resp, types.GET_LAYOUT_SUCCESS, types.GET_LAYOUT_REJECTED, dispatch);
    })
    .catch(error => {
      dispatch({
        type: types.GET_LAYOUT_REJECTED,
        payload: error
      });
    });
};
