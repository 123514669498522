import './LandmarksMap.scss';
import React, { Component } from 'react';
import { Map, TileLayer, Tooltip, ZoomControl } from 'react-leaflet';
import Marker from 'react-leaflet-enhanced-marker';
import CustomMarker from './CustomMarker';
import MarkerClusterGroup from './MarkerClusterGroup';

class LandmarksMap extends Component {
  state = {
    lat: 52,
    lng: 4,
    zoom: 12
  };

  checkForSelected = id => {
    return this.props.selectedLandmarks.includes(id);
  };

  render() {
    const position = [this.state.lat, this.state.lng];
    const { data, onMarkerClick } = this.props;

    return (
      <Map center={position} zoom={this.state.zoom} bounds={data} maxZoom={18} zoomControl={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="bottomleft" />
        <MarkerClusterGroup>
          {data.map((point, index) => {
            return (
              <Marker
                key={index}
                position={[point.lat, point.lng]}
                riseOnHover
                icon={<CustomMarker selected={this.checkForSelected(point.id)} />}
                onClick={e => onMarkerClick(e, point)}
              >
                <Tooltip direction="top">{point.name}</Tooltip>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      </Map>
    );
  }
}

export default LandmarksMap;
