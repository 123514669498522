import React from 'react';
import { currencyFormat, numberWithCommas, percentFormat } from '../../utils/chartconfig';
import { Button } from 'react-md';
import { OverviewChartCard } from '../../components';
import { RankingsChart } from '../../charts/';

const RankingsCards = ({ data = {}, showDialog }) => {
  const listLimit = 4;
  const chartList = [
    { color: '#66bb6a', formatter: currencyFormat, id: 'totalRevenue', title: 'Total Revenue' },
    { color: '#1b751f', formatter: currencyFormat, id: 'onsiteRevenue', title: 'Onsite Revenue' },
    { color: '#673ab7', formatter: currencyFormat, id: 'prepaidRevenue', title: 'Prepaid Revenue' },
    { color: '#29b6f6', formatter: currencyFormat, id: 'creditRevenue', title: 'Credit Revenue' },
    { color: '#8bc34a', formatter: currencyFormat, id: 'cashRevenue', title: 'Cash Revenue' },
    { color: '#673ab7', formatter: numberWithCommas, id: 'redeemedPrepaidRevenue', title: 'Redeemed Prepaid' },
    { color: '#fbd648', formatter: numberWithCommas, id: 'vipsParked', title: 'VIPs Parked' },
    { color: '#ff5722', formatter: numberWithCommas, id: 'carsParkedByType', title: 'Cars Parked' },
    { color: '#ff5722', formatter: percentFormat, id: 'utilization', title: 'Utilization' },
    { color: '#4a4a4a', formatter: numberWithCommas, id: 'exemptionTypes', title: 'Exemptions' }
  ];

  return chartList.map(item => {
    const { color, formatter, id, title } = item;
    const chartData = data[id] || [];
    const itemCount = chartData.length;
    const showButton = itemCount > listLimit;

    return (
      <div className="col-xs-12 col-sm-6 col-md-4" key={id}>
        <OverviewChartCard data={chartData} title={title}>
          <RankingsChart
            color={color}
            formatter={formatter}
            listLimit={listLimit}
            nameCharLimit={45}
            valueKeyPath="value.value"
            disableAnimation={false}
          />
          {showButton && (
            <div className="bottom-action-container">
              <Button
                className="button gray"
                flat
                onClick={e => showDialog(e, chartData, `Events by ${title}`, color, formatter)}
              >
                View All({itemCount})
              </Button>
            </div>
          )}
        </OverviewChartCard>
      </div>
    );
  });
};

export default RankingsCards;
