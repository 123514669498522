import React, { Fragment } from 'react';
import { LineChart } from '../../components';
import { currencyFormat } from '../../utils/chartconfig';
import { orderByKey, timeFormatter } from '../../utils';
import { EmptyChart, RollupMetrics } from '../../components';

export const RevenueByType = ({ data = {}, noChart }) => {
  const dataAvailable = data.cash.value > 0 || data.credit.value > 0 || data.prepaid.value > 0;

  let formatData = [];
  let chartData = [];
  let legendData = [];

  if (!noChart && dataAvailable) {
    formatData = stat => {
      if (stat.timeSeriesData === null) {
        stat.timeSeriesData = [];
      }

      return orderByKey(stat.timeSeriesData, 'timestamp').map(x => {
        let entry = [];
        const val = parseFloat(x.value);
        const formattedVal = val === 0 ? val : val / 100;
        entry.push(timeFormatter(x.timestamp), formattedVal);

        return entry;
      });
    };

    chartData = [
      { name: 'Cash', data: formatData(data.cash) },
      { name: 'Credit', data: formatData(data.credit) },
      { name: 'Prepaid', data: formatData(data.prepaid) }
    ];

    legendData = [
      {
        name: 'Cash', y: currencyFormat((data.cash.value))
      },
      {
        name: 'Credit', y: currencyFormat((data.credit.value))
      },
      {
        name: 'Prepaid', y: currencyFormat((data.prepaid.value))
      }
    ];
  }

  return (
    <Fragment>
      {dataAvailable ? noChart ?
        <RollupMetrics
          data={[
            { label: 'Cash', value: currencyFormat(data.cash.value) },
            { label: 'Credit', value: currencyFormat(data.credit.value) },
            { label: 'Prepaid', value: currencyFormat(data.prepaid.value) }
          ]}
        /> :
        <LineChart data={chartData} legendData={legendData} timezone={data.timezone} /> :
        <EmptyChart />
      }
    </Fragment>
  );
};
