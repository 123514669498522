import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/root.reducer';

const history = createBrowserHistory();

const defaultState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(connectRouter(history)(rootReducer), defaultState, composeEnhancers(applyMiddleware(routerMiddleware(history), promise(), thunk)));

  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('../reducers/root.reducer', () => {
      store.replaceReducer(connectRouter(history)(rootReducer));
    });
  }

  return store;
};

const store = configureStore();

export {
  history,
  store
};
