import React, { Fragment } from 'react';
import { RollupMetrics } from '../../components';
import { EmptyChart } from '../../components';
import { currencyFormat } from '../../utils/chartconfig';

export const RevenueRollups = (props) => {
  const { data } = props;

  return (
    <Fragment>
      {data ?
        <RollupMetrics
          data={[
            {
              label: 'Total Parking Revenue',
              value: currencyFormat(data.total.value)
            },
            {
              label: 'Prepaid Parking Revenue',
              value: currencyFormat(data.prepaid.value)
            },
            {
              label: 'Onsite Parking Revenue',
              value: currencyFormat(data.onsite.value)
            }
          ]}
        />
        :
        <EmptyChart />
      }
    </Fragment>
  );
};
