import React, { Fragment } from 'react';
import { DonutChart } from '../../components';
import { kFormatter } from '../../utils/chartconfig';
import { EmptyChart } from '../../components';

export const TotalTransactions = ({ data = {} }) => {
  const dataAvailable = data.total.value > 0;
  const colors = {
    Successful: '#66BB6A',
    Failed: '#E53935'
  };

  let formattedData = [];

  if (dataAvailable) {
    formattedData = (() => {
      let formattedArray = [];
      for (let x in data) {
        if (data.hasOwnProperty(x) && x !== 'displayName' && x !== 'total') {
          formattedArray.push({
            name: data[x].label ? data[x].label : 'Unknown Type',
            y: parseFloat(data[x].value.toFixed(1)),
            color: colors[data[x].label],
            selected: true
          });
        };
      };

      return formattedArray;
    })();
  }

  return (
    <Fragment>
      {dataAvailable 
        ? <DonutChart total={kFormatter(data.total.value)} subtitle="Transactions" data={formattedData} name="Transactions" />
        : <EmptyChart />
      }
    </Fragment>
  );
};
