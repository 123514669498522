import './FilterChip.scss';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontIcon, TextField, Button } from 'react-md';
import { idFormatter } from '../../utils/';

export class FilterChipInput extends Component {

  static propTypes = {
    direction: PropTypes.string,
    filterKey: PropTypes.string,
    label: PropTypes.string.isRequired,
    onApply: PropTypes.func,
    onRemove: PropTypes.func
  }

  static defaultProps = {
    direction: 'right'
  }

  constructor(props) {
    super(props);
    this._element = React.createRef();
  }

  state = {
    data: '',
    expanded: false,
    searchTerm: ''
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false);

    if (this.props.data) {
      this.updateData(this.props.data);
    }

    // shameful hack - style container to prevent break in flow
    const containerElement = this._element.current.parentElement.parentElement;

    setTimeout(() => {
      const width = containerElement.clientWidth;
      containerElement.style.minWidth = width + 0 + 'px';
    }, 1000);
  }

  componentDidCatch(error) {
    console.log(error);
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  updateData = (data) => {
    this.setState({
      data
    });
  }

  handleClick = (e) => {
    if (this._element.current.contains(e.target)) {
      return;
    }

    if (e.target.id === 'clear-filter-btn') {
      this.setState({
        searchTerm: ''
      });
    }

    this.setState({
      expanded: false
    });
  }

  isRemovable = () => {
    const { searchTerm } = this.state;
    return !!searchTerm;
  }

  handleSetExpand = (value, e) => {
    e.stopPropagation();
    this.setState({ expanded: value });
  }

  handleApply = (value, e) => {
    e.stopPropagation();
    this.setState({
      expanded: value
    });

    this.props.onApply(this.state.searchTerm, this.props.filterKey);
  }

  handleRemove = (e) => {
    e.stopPropagation();
    const { onApply, onRemove, filterKey } = this.props;
    this.setState(() => ({ searchTerm: '' }),
      () => onApply(null, filterKey));

    if (onRemove) {
      onRemove(null, filterKey);
    }
  }

  handleSearch = (searchTerm) => {
    this.setState({ searchTerm });
  }

  render() {
    const { label, direction } = this.props;
    const { expanded } = this.state;

    return (
      <Fragment>
        {!expanded &&
          <div ref={this._element} id={idFormatter(label)}>
            <div className={`filter-chip ${this.isRemovable() ? 'removable' : ''}`} onClick={(e) => this.handleSetExpand(true, e)}>
              <div className="filter-chip-header">
                <span className="chip-label">
                  {label}
                </span>
                <Fragment>
                  {this.isRemovable() &&
                    <button id={`${idFormatter(label)}-filter-cancel`} className="chip-remove-button" onClick={(e) => this.handleRemove(e)}>
                      <FontIcon>cancel</FontIcon>
                    </button>
                  }
                  {!expanded && !this.isRemovable() &&
                    <span id={`${idFormatter(label)}-filter-drop-down`} className="chip-icon">
                      <FontIcon>arrow_drop_down</FontIcon>
                    </span>
                  }
                </Fragment>
              </div>
            </div>
          </div>
        }
        {expanded &&
          <div ref={this._element} id={idFormatter(label)}>
            <div className={`filter-chip expanded expand-${direction}`}>
              <div className="filter-chip-header">
                <span className="chip-label">
                  {label}
                </span>
                <button id={`${idFormatter(label)}-filter-close-chip`} className="chip-close-icon" onClick={(e) => this.handleSetExpand(false, e)}>
                  <FontIcon>close</FontIcon>
                </button>
              </div>
              <div className="filter-chip-dropdown">
                <h5 className="chip-section-header">Search</h5>
                <TextField
                  id={`${idFormatter(label)}-search-input`}
                  placeholder="What are you looking for...?"
                  rightIcon={<FontIcon>search</FontIcon>}
                  fullWidth
                  onChange={(v) => this.handleSearch(v)}
                />
                <br />
                <div className="row end-xs">
                  <div className="col-xs">
                    <Button id={`${idFormatter(label)}-filter-apply`} flat secondary swapTheming onClick={(e) => this.handleApply(false, e)}>Apply</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}
