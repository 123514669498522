import './DonutChart.scss';
import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsChart, Chart, withHighcharts, Title, PieSeries, Subtitle, Tooltip } from 'react-jsx-highcharts';
import { CHART_COLORS } from '../../utils/chartconfig';
import ChartLegend from '../ChartLegend/ChartLegend';

const DonutChart = ({ breakdown, colors, data, layout, legend, legendData, name, subtitle, total }) => (
  <>
    <HighchartsChart chart={{ height: '220px' }} className="donut-chart">
      <Chart />
      <Tooltip backgroundColor="white" />
      <Title
        align="center"
        className="donut-title"
        verticalAlign="middle"
        y={0}
        style={{
          fontSize: '32px'
        }}
      >
        {total}
      </Title>
      <Subtitle
        align="center"
        className="donut-subtitle"
        verticalAlign="middle"
        y={20}
        style={{
          fontSize: '17px',
          color: 'rgba(0, 0, 0, 0.55)'
        }}
      >
        {subtitle || ''}
      </Subtitle>
      <PieSeries
        borderWidth={0}
        center={['50%', '50%']}
        colors={colors ? colors : CHART_COLORS}
        data={data}
        dataLabels={{ enabled: false }}
        depth={3}
        id="pie-series"
        innerSize="75%"
        name={name}
        size="194px"
      />
    </HighchartsChart>
    {legend !== 'off' ? (
      <ChartLegend breakdown={breakdown} data={legendData || data} type="donut" layout={layout} />
    ) : (
      <div style={{ height: '40px' }} />
    )}
  </>
);

export default withHighcharts(DonutChart, Highcharts);
