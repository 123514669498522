import './Table.scss';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { HighlightOffIcon, ErrorOutlineIcon, CircleCheckIcon } from '../../assets/react-icons';
import { convertToUSD } from '../../utils';
import { ThItem } from './ThItem';
import { makeTimeReadable, resolvePropDepth } from '../../utils';

const TableComponent = ({ data, defaultSortKey, defaultSortDirection, timezone, selectedIds, labelKeys, onRowClick, title, selectable, iconBefore }) => {
  const [stateData, setData] = useState([]);
  const [activeSortKey, setActiveSortKey] = useState('');
  const [activeSortDirection, setActiveSortDirection] = useState('');

  useEffect(() => {
    setData(sortData(data, defaultSortKey, defaultSortDirection));
    setActiveSortKey(defaultSortKey);
    setActiveSortDirection(defaultSortDirection);
  }, [data, defaultSortKey, defaultSortDirection]);

  useEffect(() => {
    setData(sortData(data, activeSortKey, activeSortDirection));
  }, [data, activeSortKey, activeSortDirection]);

  function sortData(data, key, direction) {
    return _.orderBy(data, [key], direction);
  }

  function formatData(key, value) {
    // TODO: FIX THIS MESS!
    if (key.includes('revenue') || key.includes('Revenue') || key === 'total') {
      return convertToUSD(parseInt(resolvePropDepth(key, value), 0));
    } else if (key.includes('utilization')) {
      return resolvePropDepth(key, value) + '%';
    } else if (key === 'inventoryRemaining') {
      return value + '%';
    } else if (key === 'transaction_time' || key === 'from') {
      let x = makeTimeReadable(value[key], timezone);
      return (<span style={{ whiteSpace: 'nowrap' }}>{x.date} {x.time}</span>);
    } else if (key === 'cashRevenue') {
      return convertToUSD(value.metrics.inventoryOverview.totalCashRevenue);
    } else if (key === 'lots.name') {
      return value.lots.map(x => x.name).join(' / ');
    } else if (key === 'segment') {
      return 'Data From Tag?';
    } else {
      return value[key] ? value[key] : resolvePropDepth(key, value) && resolvePropDepth(key, value);
    }
  }

  function setSortOptions(key, direction) {
    setData(sortData(data, key, direction));
    setActiveSortKey(key);
    setActiveSortDirection(direction);
  }

  function checkForSelected(id) {
    return selectedIds.includes(id);
  }

  const dataStyle = (data) => data.status === 'failed' ? 'rgba(239, 83, 80, 0.2)' : data.status === 'pending' && 'rgba(253, 216, 53, 0.2)';

  return (
    <div className="ph-table">
      <table>
        <thead>
          <tr>
            {labelKeys && labelKeys.map((lk, index) => {
              return (
                <th className={lk.sortable ? '' : 'disabled-sort'} key={index}>
                  <ThItem id={`table-head-${lk}-sort`} activeSortKey={activeSortKey} sortKey={lk.key} sortable={lk.sortable} onClick={setSortOptions} >{lk.label}</ThItem>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {stateData.map((item, index) => (
            <tr
              id={`table-row-${index}`}
              style={{ backgroundColor: dataStyle(item) }}
              onClick={(e) => onRowClick(e, item)}
              key={index}
              className={selectable && checkForSelected(item.id) ? 'active' : ''}
            >
              {labelKeys.map((lk, keyIndex) => (
                <td key={keyIndex} className={lk.key === 'transaction_time' ? 'date' : ''}>
                  {_.isArray(item[lk.key]) && lk.key !== 'lots' ? item[lk.key].join(', ')
                    // TODO: find alternative to using ID for icon td
                    : iconBefore && lk.key === 'id' ? iconBefore
                      : title === 'Transactions' && lk.key === 'status' ?
                        item[lk.key] === 'failed' ?
                          <HighlightOffIcon color="#EF5350" width={24} />
                          : item[lk.key] === 'pending' ?
                            <ErrorOutlineIcon color="#FDD835" width={24} />
                            : item[lk.key] === 'successful' &&
                            <CircleCheckIcon color="#00C853" width={24} />
                        : formatData(lk.key, item)
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default TableComponent;
