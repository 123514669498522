import React from 'react';
import { isNotEmpty, formatFilterValue, createFilterList } from '../../utils';
import { FilterBar, FilterChip, FilterChipSlider } from '../../components';

const CashierFilters = ({ applyFilter, filters = {}, removeFilter }) => {
  return (
    <div className="col-xs">
      {process.env.REACT_APP_FILTERS_ENABLED && filters && (
        <FilterBar>
          {isNotEmpty(filters.cashiers) && (
            <FilterChip
              id="cashiers-name-filter"
              data={createFilterList(filters.cashiers, 'name')}
              label="Cashiers"
              filterKey="cashierIdFilter"
              onApply={applyFilter}
              onRemove={() => removeFilter('cashierIdFilter')}
            />
          )}
          {isNotEmpty(filters.inventory) && (
            <FilterChip
              id="cashiers-location-filter"
              data={createFilterList(filters.inventory, 'name')}
              label="Lots"
              filterKey="inventoryIdFilter"
              onApply={applyFilter}
            />
          )}
          {isNotEmpty(filters.revenue) && filters.revenue[1] !== null && (
            <FilterChipSlider
              id="cashiers-revenue-filter"
              label="Revenue"
              filterKey="revenueFilter"
              formatLabel={value => formatFilterValue(value, '$')}
              minValue={filters.revenue[0]}
              maxValue={filters.revenue[1]}
              onApply={applyFilter}
            />
          )}
          {isNotEmpty(filters.transactions) && filters.transactions[1] !== null && (
            <FilterChipSlider
              id="cashiers-transaction-filter"
              label="Transactions"
              filterKey="transactionFilter"
              formatLabel={value => formatFilterValue(value)}
              minValue={filters.transactions[0]}
              maxValue={filters.transactions[1]}
              onApply={applyFilter}
            />
          )}
          {isNotEmpty(filters.reprints) && filters.reprints[1] !== null && (
            <FilterChipSlider
              id="cashiers-reprints-filter"
              label="Reprints"
              filterKey="reprintFilter"
              formatLabel={value => formatFilterValue(value)}
              minValue={filters.reprints[0]}
              maxValue={filters.reprints[1]}
              onApply={applyFilter}
            />
          )}
        </FilterBar>
      )}
    </div>
  );
};

export default CashierFilters;
