import './RankingsChart.scss';
import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { truncate, resolvePropDepth } from '../../utils';
import { numberWithCommas } from '../../utils/chartconfig';
import PropTypes from 'prop-types';
import IndicatorBar from './IndicatorBar';
import CountUp from 'react-countup';

const RankingsChart = ({ data, color, listLimit, valueKeyPath, nameCharLimit, formatter, disableAnimation }) => {
  function getWidthPercentage(value) {
    const maxValue = _.maxBy(data.map(item => resolvePropDepth(valueKeyPath, item))) || 0;
    const val = resolvePropDepth(valueKeyPath, value) || 0;

    return `${((val / maxValue) * 100).toFixed(0)}%`;
  };

  function limitListSize(data) {
    return data.length > listLimit ? data.slice(0, listLimit) : data;
  };

  function formatValue(item) {
    const value = resolvePropDepth(valueKeyPath, item);
    return formatter(value);
  };

  const orderedData = _.orderBy(data, [valueKeyPath], ['desc']);
  const chartData = listLimit ? limitListSize(orderedData) : orderedData;

  return (
    <div className="rankings-chart">
      <Fragment>
        {chartData.map((item, index) => (
          <div
            key={index}
            className="bar-container"
            style={{ borderLeft: `4px solid ${color}` }}
            title={nameCharLimit ? item.name : undefined}
          >
            <IndicatorBar color={color} width={getWidthPercentage(item)} disableAnimation={disableAnimation} />
            <h4>
              {resolvePropDepth(valueKeyPath, item) || resolvePropDepth(valueKeyPath, item) === 0 ? (
                disableAnimation ? (
                  formatValue(item)
                ) : (
                  <CountUp useEasing duration={1} end={resolvePropDepth(valueKeyPath, item)} formattingFn={formatter} />
                )
              ) : (
                'No Data Available'
              )}
            </h4>
            {item.name && <p className="no-break">{nameCharLimit ? truncate(item.name, nameCharLimit) : item.name}</p>}
            {item.date && (
              <p>
                {moment
                  .unix(item.date)
                  .tz(item.timezone || 'America/Chicago')
                  .format('MM/D/YY')}
              </p>
            )}
            {item.location && <p>{item.location}</p>}
          </div>
        ))}
      </Fragment>
    </div>
  );
};

RankingsChart.propTypes = {
  color: PropTypes.string,
  data: PropTypes.array,
  formatter: PropTypes.func,
  listLimit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  nameCharLimit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  valueKeyPath: PropTypes.string.isRequired
};

RankingsChart.defaultProps = {
  width: 0,
  color: '#FF5722',
  data: [],
  formatter: numberWithCommas,
  listLimit: false,
  nameCharLimit: false
};

export default RankingsChart;
