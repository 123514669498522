import React, { useRef, useState } from 'react';
import { SearchInput } from '../../../components';

const MultipleEventsSearch = ({ filterState = {}, setFilterState }) => {
  const [search, setSearch] = useState('');
  const timer = useRef(false);

  function handleSearchChange(e) {
    setSearch(e.target.value);
    clearTimeout(timer.current);
    const updateSearchFilter = () => setFilterState({ ...filterState, search });

    timer.current = setTimeout(updateSearchFilter, 1000);
  }

  return (
    <div className="col-xs-12 col-smt-3 col-sm-3">
      <div className="search-container">
        <SearchInput
          onChange={handleSearchChange}
          placeholder="Search by keyword"
          value={search}
        />
      </div>
    </div>
  );
};

export default MultipleEventsSearch;
