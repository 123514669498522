import * as types from '../actions/ActionTypes';

const INITIAL_STATE = {
  isLoading: true,
  isError: null,
  data: []
};

export function overviewReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_OVERVIEW_PENDING: {
      return {
        ...state,
        isError: null,
        isLoading: true
      };
    }
    case types.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        data: action.payload.records[0].metrics,
        timezone: action.payload.records[0].landmark.timezone,
        filters: action.payload.filters,
        isError: null,
        isLoading: false
      };
    case types.GET_OVERVIEW_REJECTED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };
    case types.CLEAR_OVERVIEW_DATA:
      return {
        ...state,
        data: [],
        filters: [],
        isError: null,
        isLoading: false
      };
    default: return state;
  }
}
