import React from 'react';
import { FontIcon } from 'react-md';

const CustomMarker = ({ selected }) => {
  const markerStyle = {
    display: 'block',
    color: selected ? '#2196F3' : '#858585',
    fontSize: '48px'
  };

  return <FontIcon style={markerStyle}>room</FontIcon>;
};

export default CustomMarker;
