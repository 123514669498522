import React from 'react';
import { FontIcon } from 'react-md';
import { Table } from '../../../components';

const MultipleEventsTable = ({ events, selectSingleEvent, selectedEvents }) => {
  const eventsLabelKeys = [
    { label: '', key: 'id', sortable: false },
    { label: 'Event Name', key: 'name', sortable: true },
    { label: 'Venue Name', key: 'landmark.name', sortable: true },
    { label: 'Date', key: 'from', sortable: true }
  ];
  const LandmarkIcon = (
    <FontIcon style={{ color: 'rgba(0,0,0,0.6)', fontSize: '24px' }}>
      room
    </FontIcon>
  );

  return (
    <div className="col-xs-12">
      <Table
        title="Landmarks"
        iconBefore={LandmarkIcon}
        data={events}
        labelKeys={eventsLabelKeys}
        selectable
        defaultSortKey={eventsLabelKeys[3].key}
        defaultSortDirection="desc"
        selectedIds={selectedEvents}
        onRowClick={selectSingleEvent}
      />
    </div>
  );
};

export default MultipleEventsTable;
