import React from 'react';
import {
  CarsParkedByType,
  CreditRevenue,
  ExemptionTypes,
  PermitTypes,
  PrepaidBreakdown,
  RedeemedPrepaids,
  Reprints,
  RevenueByType,
  RevenueRollups,
  TotalTransactions,
  Utilization,
  VipsParked
} from '../../charts/';
import { OverviewChartCard } from '../../components';

const AverageCards = ({ data = {} }) => {
  const cards = [
    [
      { content: <RevenueRollups />, id: 'revenueRollups', title: 'Average Revenue Rollups' },
      {
        content: <TotalTransactions />,
        id: 'totalTransactions',
        title: 'Average Total Transactions'
      },
      {
        content: <CarsParkedByType />,
        id: 'carsParkedByType',
        title: 'Average Cars Parked by Type'
      },
      { content: <RedeemedPrepaids />, id: 'redeemedPrepaids', title: 'Average Redeemed Prepaids' },
      { content: <Reprints noChart />, id: 'receiptReprints', title: 'Average Reprints' }
    ],
    [
      { content: <RevenueByType noChart />, id: 'revenueByType', title: 'Average Revenue by Type' },
      { content: <PrepaidBreakdown />, id: 'prepaidBreakdown', title: 'Average Prepaid Breakdown' },
      { content: <VipsParked average />, id: 'vipsParked', title: 'Average VIPs Parked' },
      { content: <Utilization />, id: 'utilization', title: 'Average Utilization' },
      { content: <CreditRevenue />, id: 'creditRevenue', title: 'Average Credit Revenue' }
    ],
    [
      { content: <ExemptionTypes />, id: 'exemptionTypes', title: 'Average Exemption Types' },
      { content: <PermitTypes />, id: 'permitTypes', title: 'Average Permit Types' }
    ]
  ];

  return cards.map((column, columnIndex) => (
    <section className="chart-column col-xs-12 col-sm-6 col-md-4" key={`column-${columnIndex}`}>
      <div className="row">
        {column.map(card => {
          const { content, id, title } = card;

          return (
            <div className="col-xs-12" key={id}>
              <OverviewChartCard title={title} data={data[id]}>
                {content}
              </OverviewChartCard>
            </div>
          );
        })}
      </div>
    </section>
  ));
};

export default AverageCards;
