import './SideMenu.scss';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, FontIcon, ResizeObserver } from 'react-md';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

const SideMenu = ({ activeRoute }) => {
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [favoritesText, setFavoritesText] = useState('Favorites');
  const [comparisonText, setComparisonText] = useState('Comparison');

  function handleResize({ width }) {
    setCollapseMenu(width <= 1024);
  };

  function onMouseOver(text) {
    if (text === 'Comparison') {
      setComparisonText('Coming soon!');
    }
    if (text === 'Favorites') {
      setFavoritesText('Coming soon!');
    }
  }

  function onMouseLeave(text) {
    if (text === 'Comparison') {
      setComparisonText('Comparison');
    }
    if (text === 'Favorites') {
      setFavoritesText('Favorites');
    }
  }

  return (
    <Fragment>
      <aside className={`sidebar ${collapseMenu ? 'collapsed' : ''}`}>
        {collapseMenu ?
          <div className="side-menu-small">
            <List>
              <ListItem
                leftIcon={<FontIcon key="event">event</FontIcon>}
                primaryText="" className={activeRoute.startsWith(routes.DAILYEVENT) ? 'active' : ''}
                component={Link}
                to={`${routes.DAILYEVENT}`}
              />
              <ListItem
                leftIcon={<FontIcon key="schedule">schedule</FontIcon>}
                primaryText="" className={activeRoute.startsWith(routes.ROLLUPS) ? 'active' : ''}
                component={Link}
                to={`${routes.ROLLUPS}`}
              />
            </List>
          </div>
          :
          <div className="side-menu">
            <List>
              <ListItem
                leftIcon={<FontIcon key="event">event</FontIcon>}
                primaryText="Daily / Event"
                className={activeRoute.includes(routes.DAILYEVENT) ? 'active' : ''}
                component={Link} to={`${routes.DAILYEVENT}`}
              />
              <ListItem
                leftIcon={<FontIcon key="schedule">schedule</FontIcon>}
                primaryText="Roll-ups"
                className={activeRoute.includes(routes.ROLLUPS) ? 'active' : ''}
                component={Link} to={`${routes.ROLLUPS}`}
              />
              <ListItem
                leftIcon={<FontIcon key="developer_board">developer_board</FontIcon>}
                primaryText={comparisonText}
                onMouseLeave={() => onMouseLeave('Comparison')}
                onMouseOver={() => onMouseOver('Comparison')}
                disabled
              />
              <ListItem
                leftIcon={<FontIcon key="star">star</FontIcon>}
                primaryText={favoritesText}
                onMouseLeave={() => onMouseLeave('Favorites')}
                onMouseOver={() => onMouseOver('Favorites')}
                disabled
              />
            </List>
          </div>
        }
        <ResizeObserver watchWidth target="root" onResize={handleResize} />
      </aside>
    </Fragment>
  );
};

SideMenu.propTypes = {
  activeRoute: PropTypes.string
};

export default SideMenu;
