import React from 'react';
import { Button } from 'react-md';
import { FilterBar, FilterChip, FilterChipSlider, FilterChipInput } from '../../components';
import { isNotEmpty, createFilterList } from '../../utils/';
import moment from 'moment';

const TransactionActionBar = ({ applyFilter, filters, handleDownload }) => {

  const formatLabel = value => moment.unix(value).format('h:mm a');

  return (
    <section className="top-action-bar">
      <div className="row row-clear">
        <div className="col-xs-12 col-lg">
          {process.env.REACT_APP_FILTERS_ENABLED && isNotEmpty(filters) && (
            <FilterBar>
              {isNotEmpty(filters.status) &&
                <FilterChip
                  id="transactions-status-filter"
                  label="Status"
                  data={createFilterList(filters.status, 'name')}
                  filterKey="statusFilter"
                  onApply={applyFilter}
                />
              }
              {isNotEmpty(filters.type) &&
                <FilterChip
                  id="transactions-price-filter"
                  label="Pricing Type"
                  data={createFilterList(filters.type, 'name')}
                  filterKey="pricePointFilter"
                  onApply={applyFilter}
                />
              }
              {isNotEmpty(filters.method) &&
                <FilterChip
                  id="transactions-method-filter"
                  label="Method"
                  data={createFilterList(filters.method, 'name')}
                  filterKey="methodFilter"
                  onApply={applyFilter}
                />
              }
              {isNotEmpty(filters.cashiers) &&
                <FilterChip
                  id="transactions-cashiers-filter"
                  label="Cashiers"
                  data={createFilterList(filters.cashiers, 'name')}
                  filterKey="cashierIdFilter"
                  onApply={applyFilter}
                />
              }
              {isNotEmpty(filters.inventory) &&
                <FilterChip
                  id="transactions-location-filter"
                  label="Lots"
                  data={createFilterList(filters.inventory, 'name')}
                  filterKey="inventoryIdFilter"
                  onApply={applyFilter}
                  direction="left"
                />
              }
              {isNotEmpty(filters.time) && filters.time[1] !== null &&
                <FilterChipSlider
                  id="transactions-time-filter"
                  label="Time"
                  filterKey="timeFilter"
                  formatLabel={formatLabel}
                  minValue={filters.time[0]}
                  maxValue={filters.time[1]}
                  onApply={applyFilter}
                  direction="right"
                />
              }
              <FilterChipInput
                id="transactions-confirmation-id"
                label="Confirmation ID"
                filterKey="search"
                onApply={applyFilter}
                direction="right"
              />
            </FilterBar>)}
        </div>
        <div className="action-container col-xs-12 col-lg-12 right">
          <Button
            id="transactions-download-button"
            onClick={() => handleDownload()}
            className="action-button large"
            flat
            iconChildren="get_app"
          >
            Download
          </Button>
        </div>
      </div>
    </section>
  );
};

export default TransactionActionBar;
