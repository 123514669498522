import './InventoryCard.scss';
import React from 'react';
import { Card } from 'react-md';
import { CashIcon, DataUsage, TransactionIcon } from '../../assets/react-icons';
import { convertToUSD, percentFormat } from '../../utils';

const InventoryCard = ({ item = {}, labels = [] }) => {
  const {
    metrics: {
      inventoryOverview: {
        revenue = 0,
        totalCashiers = 0,
        transactions = 0,
        utilization = 0
      } = {}
    } = {},
    name = ''
  } = item;

  const inventoryInfo = [
    {
      icon: <DataUsage color="rgba(255, 87, 34, 0.8)" width={24} />,
      label: labels[0],
      metric:
        typeof utilization === 'number' ? percentFormat(utilization) : 'No Data'
    },
    {
      icon: <TransactionIcon color="#009688" width={24} />,
      label: labels[1],
      metric: transactions
    },
    {
      icon: <CashIcon color="#8BC34A" width={24} />,
      label: labels[2],
      metric: typeof revenue === 'number' ? convertToUSD(revenue) : 'No Data'
    }
  ];

  return (
    <Card className="inventory-card">
      <div className="top-title" style={{ backgroundColor: '#FFF' }}>
        <h4>{name}</h4>
        <div className="card-subtitle">{totalCashiers} Cashiers</div>
      </div>
      <ul>
        {inventoryInfo.map(item => {
          const { icon, label, metric } = item;

          return (
            <li key={label}>
              <span className="icon">{icon}</span>
              <div className="data">
                <span className="metric">{metric}</span>
                <span className="label">{label}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};

export default InventoryCard;
