import React, { Fragment } from 'react';
import { LineChart } from '../../components';
import { orderByKey, timeFormatter } from '../../utils';
import { EmptyChart, RollupMetrics } from '../../components';

export const Reprints = ({ data = {}, noChart }) => {
  const dataAvailable = data.total.value > 0;

  let formattedData = [];
  let chartData = [];
  let total = 0;

  if (!noChart && dataAvailable) {
    formattedData = orderByKey(data.timeSeriesData, 'timestamp').map(x => {
      let entry = [];
      entry.push(timeFormatter(x.timestamp), x.value);

      return entry;
    });

    chartData = [{ name: 'Reprints', data: formattedData }];
    total = data.total.value;
  }

  return (
    <Fragment>
      { dataAvailable ? noChart ?
        <RollupMetrics
          data={[{
            label: 'Receipt Reprints',
            value: parseFloat(data.total.value.toFixed(1))
          }]}
        /> : <LineChart data={chartData} total={total} timezone={data.timezone} /> :
        <EmptyChart />
      }
    </Fragment>
  );
};
