import React from 'react';
import { orderByKey, timeFormatter } from '../../utils';
import { AvgChart, EmptyChart } from '../../components';

export const TransactionalRate = ({ data = {} }) => {
  const { timeSeriesData = [], transactionRate = {} } = data;
  const dataAvailable = timeSeriesData.length > 0;

  let formattedData = [];
  let chartData = [];
  let total = 0;

  if (dataAvailable) {
    formattedData = orderByKey(data.timeSeriesData, 'timestamp').map(x => {
      const entry = [];
      entry.push(timeFormatter(x.timestamp), x.value);

      return entry;
    });

    chartData = [{ name: 'Transactions', data: formattedData }];
    total = transactionRate.value.toFixed(0);
  }

  return dataAvailable ? <AvgChart data={chartData} total={total} /> : <EmptyChart />;
};
