import './Cashiers.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import CashierCards from './CashierCards';
import CashierFilters from './CashierFilters';
import { ClearFiltersMessage, OverviewDialog, ViewToggle } from '../../components';
import { getCashiersByEvent, clearCashierData } from '../../actions/cashiers.action';
import { sendPageTitle } from '../../utils';
import CashierTable from './CashierTable';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { useRouteMatch } from 'react-router-dom';

const Cashiers = ({ clearCashierData, data = [], filters = {}, getCashiersByEvent, isLoading = true }) => {
  const match = useRouteMatch();
  const { params: { id } = {} } = match;
  const clearedDialog = {
    pageX: null,
    pageY: null,
    dialogData: {},
    dialogVisible: false
  };

  const clearedFilters = {
    cashierIdFilter: null,
    inventoryIdFilter: null,
    reprintFilter: null,
    revenueFilter: null,
    transactionFilter: null
  };

  const [dialogState, setDialogState] = useState(clearedDialog);
  const [filterState, setFilterState] = useState(clearedFilters);
  const [view, setView] = useState('card');

  useMemo(() => {
    if (id) {
      getCashiersByEvent(id, filterState);
    }
  }, [filterState, getCashiersByEvent, id]);

  useEffect(() => {
    sendPageTitle('Cashiers');

    return () => clearCashierData();
  }, [clearCashierData]);

  const showDialog = (e, dialogData) => {
    let { pageX, pageY } = e;
    if (e.changedTouches) {
      pageX = e.changedTouches[0].pageX;
      pageY = e.changedTouches[0].pageY;
    }

    setDialogState({ dialogData, dialogVisible: true, pageX, pageY });
  };

  const hideDialog = () => setDialogState({ ...dialogState, dialogVisible: false });

  function applyFilter(value, key) {
    return setFilterState({ ...filterState, [key]: value });
  }

  function removeFilter(key) {
    setFilterState({ ...filterState, [key]: clearedFilters[key] });
  }

  const clearAllFilters = () => setFilterState(clearedFilters);

  const dialogName = dialogState.dialogData.name
    ? dialogState.dialogData.name
    : `${dialogState.dialogData.first_name} ${dialogState.dialogData.last_name}`;

  return (
    <>
      <div id="cashiers">
        <section className="top-action-bar">
          <CashierFilters filters={filters} applyFilter={applyFilter} removeFilter={removeFilter} />
          <div className="row row-clear middle-xs end-xs">
            <div className="action-container">
              <ViewToggle toggle={setView} view={view} />
            </div>
          </div>
        </section>
        <section className="content-container">
          {view === 'list' ? (
            <CashierTable cashiersData={data} showDialog={showDialog} />
          ) : (
            <CashierCards cashiers={data} showDialog={showDialog} />
          )}
        </section>
      </div>
      <OverviewDialog
        visible={dialogState.dialogVisible}
        pageX={dialogState.pageX}
        pageY={dialogState.pageY}
        onHide={hideDialog}
        data={dialogState.dialogData}
        title={`Cashiers / ${dialogName}`}
      />
      <LoadingSpinner show={isLoading} delay={1000} />
      {!isLoading && data.length <= 0 && <ClearFiltersMessage clearAllFilters={clearAllFilters} />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state.cashiers.data,
    filters: state.cashiers.filters,
    isError: state.cashiers.isError,
    isLoading: state.cashiers.isLoading
  };
};

export default connect(mapStateToProps, {
  getCashiersByEvent,
  clearCashierData
})(Cashiers);
