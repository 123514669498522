import './Home.scss';
import ParkhubLogo from '../../assets/parkhub-analytics-logo.svg';
import React, { useEffect } from 'react';
import { useHistory, useLocation, Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { SideMenu } from '../../components/';
import DailyEvent from '../DailyEvent/DailyEvent';
import Rollups from '../Rollups/Rollups';
import * as routes from '../../constants/routes';
import { Header } from '@parkhub/parkhub-ui';
import { signOut } from '../../utils';

const Home = props => {
  const { authUser = {} } = props;
  const history = useHistory();
  const location = useLocation();
  const { key = '', pathname = '' } = location;

  useEffect(() => {
    const setDefaultRoute = () => {
      if (pathname === routes.ROOT) {
        history.replace(routes.DAILYEVENT);
      }
    };

    setDefaultRoute();
  }, [history, pathname]);

  return (
    <>
      <Header
        authUser={authUser}
        backgroundColor="#FF5722"
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={204}
        buttonColor="#FFF"
        appMenuActive
        style={{ borderBottom: 0 }}
        logoClick={() => history.push(routes.ROOT)}
      />
      <main id="home">
        <SideMenu activeRoute={pathname} />
        <div className="events-container">
          <TransitionGroup component="div" className="wrapper">
            <CSSTransition key={key} classNames="slide" timeout={3000}>
              <Switch location={location}>
                <Route exact path={`${routes.DAILYEVENT}`}>
                  <DailyEvent {...props} />
                </Route>
                <Route path={`${routes.ROLLUPS}`}>
                  <Rollups {...props} />
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </main>
    </>
  );
};

export default Home;
