import './SearchInput.scss';
import React from 'react';
import { SearchIcon } from '../../assets/react-icons/';

const SearchInput = props => (
  <div className="search-input-container">
    <input type="text" {...props} />
    <span>
      <SearchIcon color="rgba(0,0,0,.6)" width={24} />
    </span>
  </div>
);

export default SearchInput;
