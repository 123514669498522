import * as types from '../actions/ActionTypes';
import { isNotEmpty } from '../utils';

const INITIAL_STATE = {
  isLoading: true,
  isError: null,
  data: []
};

export function cashiersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_CASHIERS_PENDING: {
      return {
        ...state,
        isError: null,
        isLoading: true
      };
    }
    case types.GET_CASHIERS_SUCCESS:
      return {
        ...state,
        data: action.payload.records[0].cashier.records,
        filters: isNotEmpty(state.filters) ? state.filters : action.payload.records[0].cashier.filters,
        isError: null,
        isLoading: false
      };
    case types.GET_CASHIERS_REJECTED:
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };
    case types.CLEAR_CASHIER_DATA:
      return {
        ...state,
        data: [],
        filters: [],
        isError: null,
        isLoading: false
      };
    default: return state;
  }
}
