import React from 'react';
import {
  FilterChip,
  FilterChipDateRange,
  FilterBar
} from '../../../components';
import { createFilterList, isNotEmpty } from '../../../utils';

const MultipleEventsFilter = ({
  clearedFilterState,
  filters = {},
  filterState = {},
  setFilterState
}) => {
  function applyFilter(value, key) {
    return setFilterState({ ...filterState, [key]: value });
  }

  function removeFilter(key) {
    return setFilterState({ ...filterState, [key]: clearedFilterState[key] });
  }

  return (
    <div className="col-xs-12 col-sm">
      {process.env.REACT_APP_FILTERS_ENABLED && isNotEmpty(filters) && (
        <FilterBar>
          {isNotEmpty(filters.products) && (
            <FilterChip
              data={createFilterList(filters.products, 'name')}
              direction="right"
              id="rollup-event-type-filter"
              label="Event Type"
              onApply={applyFilter}
              filterKey="productIdFilter"
            />
          )}
          {isNotEmpty(filters.time) && (
            <FilterChipDateRange
              direction="right"
              endDate={filters.time[1]}
              id="rollup-event-date-filter"
              label="Date"
              onApply={applyFilter}
              onRemove={removeFilter}
              startDate={filters.time[0]}
              filterKey="timeFilter"
            />
          )}
        </FilterBar>
      )}
    </div>
  );
};

export default MultipleEventsFilter;
