import React from 'react';
import {
  Activity,
  CarsParkedByType,
  CreditRevenue,
  ExemptionTypes,
  PermitTypes,
  PrepaidBreakdown,
  RedeemedPrepaids,
  Reprints,
  RevenueByType,
  RevenueRollups,
  VipsParked,
  TotalTransactions
} from '../../charts/';
import { OverviewChartCard } from '../../components';
import { Utilization } from '../../charts/Utilization/Utilization';

const OverviewCards = ({ data = {}, timezone }) => {
  const cards = [
    [
      { content: <RevenueRollups />, cardData: data['revenueRollups'], title: 'Revenue Rollups' },
      { content: <TotalTransactions />, cardData: data['totalTransactions'], title: 'Total Transactions' },
      { content: <CarsParkedByType />, cardData: data['carsParkedByType'], title: 'Cars Parked by Type' },
      { content: <RedeemedPrepaids />, cardData: data['redeemedPrepaids'], title: 'Redeemed Prepaids' },
      { content: <Reprints />, cardData: data['receiptReprints'], title: 'Reprints' }
    ],
    [
      { content: <RevenueByType />, cardData: { ...data['revenueByType'], timezone }, title: 'Revenue by Type' },
      { content: <PrepaidBreakdown />, cardData: data['prepaidBreakdown'], title: 'Prepaid Breakdown' },
      { content: <VipsParked />, cardData: data['vipsParked'], title: 'VIPs Parked' },
      { content: <CreditRevenue />, cardData: data['creditRevenue'], title: 'Credit Revenue' }
    ],
    [
      { content: <Activity />, cardData: data['activity'], title: 'Activity' },
      { content: <ExemptionTypes />, cardData: data['exemptionTypes'], title: 'Exemption Types' },
      { content: <Utilization />, cardData: data['utilization'], title: 'Utilization' },
      { content: <PermitTypes />, cardData: data['permitTypes'], title: 'Permit Types' }
    ]
  ];

  return (
    <div className="row">
      {cards.map((column, columnIndex) => (
        <section className="chart-column col-xs-12 col-sm-6 col-md-4" key={`column-${columnIndex}`}>
          <div className="row">
            {column.map(card => {
              const { content, cardData, title } = card;
              const cardKey = title
                .split(' ')
                .join('-')
                .toLowerCase();

              return (
                <div className="col-xs-12" key={cardKey}>
                  <OverviewChartCard title={title} data={cardData}>
                    {content}
                  </OverviewChartCard>
                </div>
              );
            })}
          </div>
        </section>
      ))}
    </div>
  );
};

export default OverviewCards;
