import './Subheader.scss';
import React from 'react';
import { makeTimeReadable } from '../../utils';
import { Button } from 'react-md';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

const Subheader = ({ data = {}, isDailyEvent }) => {
  const { event: { records = [] } = {} } = data;
  const singleEventName = records[0].name;
  const headline = isDailyEvent ? singleEventName : 'Custom Rollup';
  const { DAILYEVENT, ROLLUPS, MULTIPLEEVENTS } = routes;
  const backPath = isDailyEvent ? DAILYEVENT : ROLLUPS + MULTIPLEEVENTS;

  function singleEventDetails() {
    const eventData = records[0];
    const {
      from,
      landmark: { name = '', timezone = 'America/Chicago' } = {}
    } = eventData;
    const eventDate = makeTimeReadable(from, timezone).date;
    const eventTime = makeTimeReadable(from, timezone).time;

    return [name, eventDate, eventTime];
  }

  // 1. Collects dates from records
  // 2. Formats dates and removes decimals
  // 3. Sorts chronologically
  // 4. Formats dates to readable version

  function dateRange() {
    const datesArray = records // 1
      .map(record => {
        const { from = '', timezone = 'America/Chicago' } = record;
        const dateAsNumber = Math.floor(Number(from)); // 2

        return { eventDate: dateAsNumber, timezone };
      })
      .sort((a, b) => (a.eventDate > b.eventDate ? 1 : -1)); // 3

    function formatDate(dateObject) {
      const { eventDate = 0, timezone } = dateObject;

      return makeTimeReadable(String(eventDate), timezone).date;
    } // 4

    const firstDate = datesArray[0];
    const lastDate = datesArray[datesArray.length - 1];

    return `${formatDate(firstDate)} — ${formatDate(lastDate)}`;
  }

  function rollupDetails() {
    const count = records.length;
    const locationCountText = `${count} Location${count !== 1 ? 's' : ''}`;

    return [locationCountText, dateRange()];
  }

  const details = isDailyEvent ? singleEventDetails() : rollupDetails();

  return (
    <div className="subheader-container wrapper">
      <Link to={backPath}>
        <Button
          className="back-button subheader-button icon-button"
          icon
          id="subheader-back-button"
          secondary
        >
          arrow_back
        </Button>{' '}
      </Link>
      <h1>{headline}</h1>
      {details.map((detail, index) => (
        <span key={index} className="details">
          {detail}
        </span>
      ))}
    </div>
  );
};

export default Subheader;
