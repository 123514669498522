export const ROOT = '/';
export const HOME = '/home';
export const DAILYEVENT = `/daily-event`;
export const ROLLUPS='/roll-ups';
export const LANDMARKS='/landmarks';
export const MULTIPLEEVENTS='/events';
export const WEEKLY = `/weekly`;
export const MONTHLY = `/monthly`;
export const COMPARISON = `/comparison`;
export const STARRED = `/starred`;
export const METRICS = `/metrics`;
export const OVERVIEW = '/overview';
export const TRANSACTIONS = '/transactions';
export const CASHIERS = '/cashiers';
export const INVENTORY = '/inventory';
export const RANKINGS = '/rankings';
export const AVERAGE = '/average';
export const SUMMATION = '/summation';
