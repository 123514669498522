import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-md';
import * as routes from '../../../constants/routes';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { LandmarksMap, NoDataMessage } from '../../../components';
import { getLandmarks } from '../../../actions/landmarks.action';
import { connect } from 'react-redux';
import { isNotEmpty } from '../../../utils';
import { setItem, removeItem } from '../../../utils/sessionStorage';
import LandmarksTable from './LandmarksTable';
import LandmarksHeader from './LandmarksHeader';

const Landmarks = ({
  getLandmarks,
  history = {},
  isLoading,
  isError,
  landmarks = [],
  match = {}
}) => {
  const { path = '' } = match;
  const { MULTIPLEEVENTS } = routes;
  const [selectedLandmarks, setSelectedLandmarks] = useState([]);
  const [view, setView] = useState('map');

  useEffect(() => getLandmarks(), [getLandmarks]);

  useEffect(() => {
    removeItem('landmarksLength');
    setItem('selectedEvents', []);
    setItem('selectedLandmarks', []);
  }, []);

  const landmarkCount = landmarks.length;

  const handleNavigate = useCallback(
    () => history.push(path + MULTIPLEEVENTS),
    [history, path, MULTIPLEEVENTS]
  );

  const checkForSelected = id => selectedLandmarks.includes(id);

  function updateSelectedLandmarks(landmarks) {
    setSelectedLandmarks(landmarks);
    setItem('selectedLandmarks', landmarks);
  }

  useEffect(() => {
    setItem('landmarksLength', landmarkCount);

    function checkForSingleLandmark(landmarks) {
      updateSelectedLandmarks([landmarks[0].id]);
      handleNavigate();
    }

    if (landmarkCount === 1) {
      checkForSingleLandmark(landmarks);
    }
  }, [handleNavigate, landmarkCount, landmarks]);

  function selectSingleLandmark(_, { id }) {
    const updatedLandmarks = checkForSelected(id)
      ? selectedLandmarks.filter(item => item !== id)
      : [...selectedLandmarks, id];

    return updateSelectedLandmarks(updatedLandmarks);
  }

  return (
    <Fragment>
      {isError && <NoDataMessage />}
      {!isLoading && !isError && (
        <div id="landmarks">
          <LandmarksHeader
            landmarks={landmarks}
            selectedLandmarks={selectedLandmarks}
            setView={setView}
            updateSelectedLandmarks={updateSelectedLandmarks}
            view={view}
          />
          <section>
            {isNotEmpty(landmarks) && view === 'map' && (
              <LandmarksMap
                data={landmarks}
                onMarkerClick={selectSingleLandmark}
                selectedLandmarks={selectedLandmarks}
              />
            )}
            {isNotEmpty(landmarks) && view === 'list' && (
              <LandmarksTable
                landmarks={landmarks}
                selectedLandmarks={selectedLandmarks}
                selectSingleLandmark={selectSingleLandmark}
              />
            )}
          </section>
          {isNotEmpty(selectedLandmarks) && (
            <Button
              className="main-action-button"
              onClick={() => handleNavigate()}
              floating
              primary
            >
              arrow_forward
            </Button>
          )}
        </div>
      )}
      <LoadingSpinner show={isLoading} delay={1000} />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  landmarks: state.landmarks.data,
  isError: state.landmarks.isError,
  isLoading: state.landmarks.isLoading
});

export default connect(mapStateToProps, {
  getLandmarks
})(Landmarks);
