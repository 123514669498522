import React, { useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { getOverviewByEvent, clearOverviewData } from '../../actions/overview.action';
import { NoDataMessage } from '../../components';
import { isNotEmpty, sendPageTitle } from '../../utils';
import OverviewActionBar from './OverviewActionBar';
import OverviewCards from './OverviewCards';

const Overview = ({
  clearOverviewData,
  data = {},
  filters = {},
  getOverviewByEvent,
  isLoading = true,
  timezone = ''
}) => {
  const match = useRouteMatch();
  const { params: { id } = {} } = match;
  const [filterState, setFilterState] = useState({
    inventoryIdFilter: [],
    productIdFilter: []
  });

  useMemo(() => {
    if (id) {
      getOverviewByEvent(id, filterState);
    }
  }, [filterState, getOverviewByEvent, id]);

  useEffect(() => {
    sendPageTitle('Overview');

    return () => clearOverviewData();
  }, [clearOverviewData, id]);

  function applyFilter(value, key) {
    setFilterState(filterState => ({ ...filterState, [key]: value }));
  }

  function removeFilter(key) {
    setFilterState(filterState => ({ ...filterState, [key]: [] }));
  }

  return (
    <>
      <div id="overview">
        <OverviewActionBar applyFilter={applyFilter} filters={filters} removeFilter={removeFilter} />

        {isNotEmpty(data) ? <OverviewCards data={data} timezone={timezone} /> : !isLoading && <NoDataMessage />}
      </div>
      <LoadingSpinner show={isLoading} delay={1000} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state.overview.data,
    filters: state.overview.filters,
    isError: state.overview.isError,
    isLoading: state.overview.isLoading,
    timezone: state.overview.timezone
  };
};

export default connect(mapStateToProps, {
  getOverviewByEvent,
  clearOverviewData
})(Overview);
