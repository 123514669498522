import './Layout.scss';
import ParkhubLogo from '../../assets/parkhub-analytics-logo.svg';
import React, { useEffect } from 'react';
import { Subheader, TabNavigation } from '../../components';
import { useHistory, useLocation, useRouteMatch, Switch, Route } from 'react-router-dom';
import Overview from '../Overview/Overview';
import Inventory from '../Inventory/Inventory';
import Cashiers from '../Cashiers/Cashiers';
import Transactions from '../Transactions/Transactions';
import Rankings from '../Rankings/Rankings';
import Average from '../Average/Average';
import Summation from '../Summation/Summation';
import NoMatch from '../NoMatch/NoMatch';
import * as routes from '../../constants/routes';
import { connect } from 'react-redux';
import { getLayoutByEvents } from '../../actions/layout.action';
import { isNotEmpty, signOut } from '../../utils';
import { getItem } from '../../utils/sessionStorage';
import { Header } from '@parkhub/parkhub-ui';

const Layout = props => {
  const { authUser = {}, data = {}, getLayoutByEvents } = props;
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { params: { id = '' } = {}, url = '' } = match;
  const { pathname = '' } = location;
  const isDailyEvent = url.includes('daily-event');

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isDailyEvent) {
      getLayoutByEvents([id]);
    } else {
      getLayoutByEvents(getItem('selectedEvents'));
    }
  }, [isDailyEvent, getLayoutByEvents, id]);

  const {
    AVERAGE,
    CASHIERS,
    DAILYEVENT,
    INVENTORY,
    METRICS,
    OVERVIEW,
    RANKINGS,
    ROLLUPS,
    ROOT,
    SUMMATION,
    TRANSACTIONS
  } = routes;

  return (
    <>
      <Header
        activeRoute={pathname}
        history={history}
        authUser={authUser}
        backgroundColor="#FF5722"
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={204}
        buttonColor="#FFF"
        appMenuActive
        style={{ borderBottom: 0 }}
        logoClick={() => history.push(ROOT)}
      >
        {isNotEmpty(data) && (
          <>
            <Subheader data={data} isDailyEvent={isDailyEvent} />
            <TabNavigation id={id} isDailyEvent={isDailyEvent} pathname={pathname} />
          </>
        )}
      </Header>
      <main id="layout">
        <div className="wrapper">
          <Switch location={location}>
            <Route component={Overview} path={`${DAILYEVENT}${METRICS}${OVERVIEW}/:id`} />
            <Route component={Inventory} path={`${DAILYEVENT}${METRICS}${INVENTORY}/:id`} />
            <Route component={Cashiers} path={`${DAILYEVENT}${METRICS}${CASHIERS}/:id`} />
            <Route component={Transactions} path={`${DAILYEVENT}${METRICS}${TRANSACTIONS}/:id`} />
            <Route component={Rankings} path={`${ROLLUPS}${METRICS}${RANKINGS}`} />
            <Route component={Average} path={`${ROLLUPS}${METRICS}${AVERAGE}`} />
            <Route component={Summation} path={`${ROLLUPS}${METRICS}${SUMMATION}`} />
            <Route component={NoMatch} />
          </Switch>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state.layout.data,
    filters: state.layout.filters,
    isLoading: state.layout.isLoading,
    isError: state.layout.isError
  };
};

export default connect(mapStateToProps, {
  getLayoutByEvents
})(Layout);
