import * as types from './ActionTypes';
import { create } from 'axios';
import compress from 'graphql-query-compress';
import { loader } from 'graphql.macro';

const cashiersQuery = loader('./queries/cashiersQuery.gql');

const ajax = create({
  timeout: 100000,
  contentType: 'application/json',
  withCredentials: true
});

export const getCashiersByEvent = (eventId, params) => dispatch => {
  dispatch({
    type: types.GET_CASHIERS_PENDING
  });

  const variables = {
    ...params,
    eventIds: [eventId]
  };

  ajax
    .get(process.env.REACT_APP_ANALYTICS_SERVER, {
      method: 'GET',
      params: {
        query: compress(cashiersQuery.loc.source.body),
        variables: variables
      }
    })
    .then(resp => {
      dispatch({
        type: types.GET_CASHIERS_SUCCESS,
        payload: resp.data.data.EventAnalytics.event
      });
    })
    .catch(error => {
      dispatch({
        type: types.GET_CASHIERS_REJECTED,
        payload: error
      });
    });
};

export const clearCashierData = () => dispatch => {
  dispatch({
    type: types.CLEAR_CASHIER_DATA
  });
};
