import React, { Fragment } from 'react';
import { DonutChart } from '../../components';
import { EmptyChart } from '../../components';
import { kFormatter } from '../../utils/chartconfig';

export const PrepaidBreakdown = props => {
  const { data } = props;
  const dataAvailable = data.total.value > 0;

  let formattedData = [];
  let accepted = [];
  let rejected = [];
  let total = 0;
  let breakdown = {
    accepted: [],
    rejected: []
  };

  if (dataAvailable) {
    const breakdownColors = {
      accepted: [
        '#69BA6D', //green
        '#C3E3C5', //valid prepaid 2 light green
        '#A5D6A7' //valid prepaid 3 light green
      ],
      rejected: [
        '#E53935', //3 same red
        '#EC8A8B', //invalid prepaid 2 light red
        '#F3B1B1' //invalid prepaid 3 light red
      ]
    };
    formattedData = (() => {
      let formattedArray = [];
      let i = 0;
      let q = 0;

      for (let x in data) {
        if (data.hasOwnProperty(x) && x !== 'displayName' && x !== 'total') {
          formattedArray.push({
            name: data[x].label ? data[x].label : 'Unknown Type',
            y: data[x].value && parseFloat(data[x].value.toFixed(1)),
            color: x.includes('accepted') ? breakdownColors.accepted[i] : x.includes('rejected') && breakdownColors.rejected[q],
            selected: true,
            entry: x.includes('accepted') && x.name !== 'Admitted' ? 'accepted' : x !== 'rejected' && x.includes('rejected') && 'rejected'
          });

          if (x.includes('accepted') && x !== 'accepted') {
            i++;
          } else if (x.includes('rejected') && x !== 'rejected') {
            q++;
          }
        }
      }

      return formattedArray;
    })();

    accepted = formattedData.filter(x => x.entry === 'accepted');
    rejected = formattedData.filter(x => x.entry === 'rejected');
    total = data.total.value;

    breakdown.accepted = accepted;
    breakdown.rejected = rejected;
  }

  return (
    <Fragment>
      {dataAvailable ? (
        <DonutChart
          breakdown={breakdown}
          data={formattedData.filter(x => x.name !== 'Admitted' && x.name !== 'Rejected')}
          layout="breakdown"
          legendData={formattedData}
          name="Passes"
          subtitle="Prepaid Passes"
          total={kFormatter(parseFloat(total.toFixed(1)))}
        />
      ) : (
        <EmptyChart />
      )}
    </Fragment>
  );
};
